<template>
    <layout>
        <loading v-if="!isLoaded" />

        <div class="crud" v-else-if="!is404">
            <aside class="main-panel" :class="{'is-active': infos.isActive, 'is-visible': infos.isVisible}">
                <div class="level">
                    <a v-bind:href="listURL" class="button">
                        <i class="material-icons">keyboard_arrow_left</i>zur Übersicht
                    </a>

                    <user-image
                        v-if="has(item, 'mainUsers')"
                        v-bind:user="item.mainUsers"
                        editable
                        v-on:input="changeUser"
                    />
                </div>


                <item-top></item-top>


                <div class="level mb">
                    <h2 class="mb-0">HAUPTINFORMATIONEN</h2>

                    <simple-menu icon="more_vert" material :buttonWrapper="false">
                        <button v-on:click.prevent="openForm"><i class="material-icons">edit</i> Bearbeiten</button>
                        <button v-on:click.prevent="deleteItem"><i class="material-icons">delete</i> Löschen</button>
                    </simple-menu>
                </div>


                <item-aside class="mb-2x"
                />

<!--                <template v-if="hasDescription">
                    <h2 class="mb">Beschreibung</h2>
                    <tip-tap v-model="description" v-on:input="storeDescription" class="mb" />
                </template>-->

                <template v-if="hasNotes">
                    <h2 class="mb">Interne Notizen</h2>
                    <tip-tap v-model="notes" v-on:input="storeNotes" class="mb" />
                </template>
            </aside>
            <div class="overlay" v-on:click="hideOverlay"></div>

            <item-tabs v-bind:tabs="tabs" @openForm="openForm" @handleTabChange="handleTabChange" />

            <item-form
                formType="modal"
                ref="form"
                v-bind:options="options"
                v-on:updated="handleFormUpdate"
            />
        </div>



        <div v-else>
            <div class="box">
                <h1>Page not found</h1>
                <a v-bind:href="listURL" class="button">
                    <i class="material-icons">keyboard_arrow_left</i>zur Übersicht
                </a>
            </div>
        </div>

        <slot />
    </layout>
</template>



<script>
import debounce from 'lodash/debounce';
import has      from 'lodash/has';

import { notifySuccess, notifyError } from '@components/Notification';
import { TipTap } from '@components/form';
import UserImage  from '@components/UserImage';
import Loading    from '@components/Loading';
import SimpleMenu from '@components/SimpleMenu';

import ItemTop   from './ItemTop';
import ItemTabs  from './ItemTabs';
import ItemAside from './ItemAside';
import { store } from './store';


export default {
    store,

    api: '',
    url: '',
    options: true,

    tabs: [],

    page: '',

    computed: {
        infos: function () { return this.$layout.infos },
        store: function () { return this.$store.state },

        api: function () { return this.$store.state.api },

        tabs: function () {
            if (!this.isMuseum) {
                return this.$options.tabs
                    .filter(item => !['entrances'].includes(item))
            }

            return this.$options.tabs;
        },
        isLoaded: function () { return this.$store.state.isLoaded },
        item: function () { return this.$store.state.data },
        options: function () { return this.$store.state.options },
        id: function () { return this.$store.state.id },
        name: function () { return this.item.name },
        is404: function () { return this.$store.state.is404 },
        isMuseum: function () { return this.$store.getters.isMuseum },

        listURL: function () {
            const url = !!this.$options.url ? this.$options.url : this.$options.api;
            return `/${url}`
        },

        // Notes
        hasNotes: function () { return has(this.item, 'notes')},
        notes: {
            get: function () { return this.item.notes },
            set: function (notes) { this.$store.commit('updateData', { notes })}
        },
        activeKey: {
            get: function () { return this.$store.state.activeKey },
            set: function (key) { this.$store.state.activeKey = key}
        },

        hasDescription: function () { return has(this.item, 'description')},
        description: {
            get: function () { return this.item.description ? this.item.description : '' },
            set: function (description) { this.$store.commit('updateData', { description })}
        },

        tabsWithOwnData () {
            return [
                'other_services',
                'documents',
                'contacts',
                'trip-details',
                'airlineRoutes',
                'todos',
                'images',
                'menus',
                'menus2',
                'hotelPrices',
                'ferryRoutes',
                'orders',
                'client_dates',
                'services',
                'otherPresetPrices',
                'infos',
                'linkedOrders',
                'linkedDayConcepts',
                'tripDetailDocuments',
                'trainRoutes',
                'day-directions',
                'procurement',
                'sellingPrices',
                'email',
                'orderConcepts',
                'users',
                'accountPlan',
                ''
            ];
        },

        _groups: function () {
            return [
                'data_check_read',
                ...(['order_concepts'].includes(this.$options.api) ? ['concept_order:destinations', 'destination_read', 'tag_trait'] : []),
                ...(['clients'].includes(this.$options.api) ? ['user_read'] : []),
                ...(['banks'].includes(this.$options.api) ? ['bank:organisation', 'organisation'] : []),
                ...(['other_service_types'].includes(this.$options.api) ? ['place_read', 'area_read', 'destination_read'] : []),
                ...(['other_service_types'].includes(this.$options.api) ? ['translations'] : []),
                ...(['hotels', 'airlines', 'ferries', 'others', 'trains'].includes(this.$options.api) ? ['agency_read_list', 'provider_property'] : ''),
                ...(['hotels'].includes(this.$options.api) ? ['place_read'] : []),
                ...(['others'].includes(this.$options.api) ? ['place_read'] : []),
                ...(['organisations'].includes(this.$options.api) && this.activeKey === 'addresses' ? ['organisation:address'] : []),
                ...this.activeKey === 'bank-accounts' ? ['bank_account_read'] : [],
                ...this.activeKey === 'conditions' ? ['provider_condition_read'] : [],
                ...this.activeKey === 'freeplaces' ? ['provider_freeplace_read'] : [],
                ...this.activeKey === 'webflow' ? ['webflow'] : [],
                ...this.activeKey === 'webflow' && this.$options.api === 'order_concepts' ? ['concept_order:webflow', 'webflow:concept_day', 'concept_order:order_checklist_item', 'destination:order_checklist_item', 'order_checklist_item', 'provider:order_checklist_item', 'concept_order:user', 'user_read'] : [],
                ...this.activeKey === 'webflow' && this.$options.api === 'day_concepts' ? ['concept_day:webflow', 'concept_day:order_checklist_item', 'order_checklist_item'] : [],
                ...this.activeKey === 'webflow' && this.$options.api === 'destinations' ? ['destination:webflow', 'destination:order_checklist_item', 'order_checklist_item'] : [],
                ...this.activeKey === 'webflow' && this.$options.api === 'hotels' ? ['provider:webflow', 'provider:order_checklist_item', 'order_checklist_item'] : [],
                ...this.activeKey === 'webflow' && ['hotels', 'trains', 'ferries', 'others', 'airlines'].includes(this.$options.api) ? ['provider:webflow'] : [],
                ...this.activeKey === 'prices' ? ['order_contingent_item_read'] : [],
                ...this.activeKey === 'addresses' ? ['address_read', 'address_read_relations', 'airport_read', 'port_read'] : [],
                ...['itemPrices', 'entrances'].includes(this.activeKey) ? ['item_price_read'] : [],
                ...this.activeKey === 'contacts' ? ['contact_read', 'contact_read_relations', 'contact_write_relations', 'address_read'] : [],
                ...this.activeKey === 'accounts' ? ['organisation_bank_account', 'organisation:organisation_bank_account', 'bank', 'organisation_bank_account:account_plan_item', 'account_plan_item', 'check_book', 'organisation:account_plan_item'] : [],
                ...this.activeKey === 'directions' ? ['order_route_predefined_read', 'predefined_free_text_item'] : [],
                ...this.activeKey=== 'images' ? ['image_folder_read', 'concept_order:image_folder'] : [],
                ...this.activeKey === 'infos' ? ['info_read', 'image_read'] : [],
                ...this.activeKey === 'menus' ? ['provider:menu', 'agency:menu', 'menu', 'menu:provider_other_service', 'other_service_read', 'translations'] : [],
                ...this.activeKey === 'sales' ? ['client:client_visit', 'client_visit', 'client:table_info', 'client_visit:contact', 'table_info', 'client_visit:user', 'user_read'] : [],
                ...this.activeKey === 'conceptCatalogText' ? ['concept_order:webflow', 'concept_day_read'] : [],
            ].filter(group => group.length > 0)
        }
    },


    methods: {
        handleTabChange (key) {
            if (key !== this.activeKey && !this.tabsWithOwnData.includes(key) && !this.$store.state.visitedTabs.includes(key)) {
                this.activeKey = key;
                this.$store.dispatch('fetchItem', { _groups: this._groups, activeKey: key});
            } else {
                this.activeKey = key;
            }

        },
        handleFormUpdate: function (data) {
            this.$store.commit('updateData', data);
        },

        openForm: function () {
            this.$refs.form.openModal(this.item);
        },

        deleteItem: function () {
            if (confirm(`Bist du sicher dass du das Element löschen willst?`)) {
                this.$store.dispatch('delete')
                    .then(() => {
                        window.location.href = this.listURL
                    })
            }
        },

        storeNotes: debounce(function (data) {
            this.$store.dispatch('storeProperty', {key: 'notes', data, update: false})
                .then(() => notifySuccess('Der Text wurde gespeichert!'), error => notifyError('Notes not saved!'))
        }, 1000),

        storeDescription: debounce(function (data) {
            this.$store.dispatch('storeProperty', {key: 'description', data, update: false})
                .then(() => notifySuccess('Die Beschreibung wurde gespeichert!'), error => notifyError('Description not saved!'))
        }, 1000),

        changeUser: function (newUser) {
            this.$store.dispatch('storeProperty', {
                key: 'mainUsers',
                data: newUser
            }).then(response => notifySuccess('Der Ansprechpartner für den Kunden wurde geändert'),
                error => notifyError('Error while saving new user!')
            )
        },

        hideOverlay: function (event) {
            this.$layout.hideInfos()
        },

        has,
    },


    created: function () {
        const ID = window.location.pathname.split('/').pop();

        this.$store.commit('updateApi', this.$options.api);
        this.$store.commit('updateID', ID);

        this.$store.dispatch('fetchItem', { _groups: this._groups, activeKey: this.activeKey});
        if (this.$options.options) {
            this.$store.dispatch('fetchOptions')
        }
    },

    mounted: function() {
        this.$layout.setPageName(this.$options.page);
        this.$layout.updateInfos({ showButton: true })
    },

    components: {
        ItemTop,
        ItemTabs,
        ItemAside,

        TipTap,

        UserImage,
        SimpleMenu,
        Loading
    }
}
</script>
