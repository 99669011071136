<template>
    <div class="redesign-table">
        <div class="table-wrapper">
            <div class="table-label" v-if="label">{{ label }}</div>
            <div class="row is-header" v-if="hasHeader">
                <div class="level is-full-width">
                    <div class="level-left" :class="tableExport ? '' : 'is-full-width'">
                        <slot name="header" />
                    </div>
                    <div class="level-right" v-if="tableExport">
                        <button class="button" :class="{ 'is-loading' : tableExport.loading }" @click.prevent.stop="$emit('export')">{{tableExport.label}}</button>
                    </div>
                </div>
            </div>

            <filters
                class="row is-filters"
                v-model="filters"
                v-on:input="refresh()"
                v-bind:options="options"
                v-if="!hasSlotFilters && !hideFilterRow"
            >
                <slot name="extraFilters"/>
            </filters>
            <slot name="filters" v-bind:options="options" />

            <!-- Head row -->
            <slot name="head" v-bind="{visibleCols}">
                <div class="row is-head">
                    <div class="cell is-icon-cell is-relative" v-if="checkable">
                        <input type="checkbox" v-model="checkAll" >
                    </div>
                    <div
                        v-for="col in visibleCols"
                        v-bind:key="col.key"
                        class="cell"
                        :style="col.style"
                        v-bind:class="{
                            'is-sortable':   col.sortable,
                            'is-order-asc':  orderBy === col.key && orderDirection === 'ASC',
                            'is-order-desc': orderBy === col.key && orderDirection === 'DESC',
                            [col.class]:     !!col.class
                        }"
                        v-on:click.prevent.stop="sortBy(col)"
                        :title="col.title"
                    >{{ col.label }}</div>



                    <div class="cell is-actions" v-if="hasActions">
                        <i class="material-icons is-pointer" v-if="isAddable" v-on:click.prevent.stop="handleAdd">add_circle</i>
                    </div>
                </div>
            </slot>

            <loading v-if="isLoading" v-bind="loadingText" />

            <template v-else>
                <!-- Data Rows -->
                <div :class="isZebra ? 'is-zebra' : ''" v-if="rows.length > 0">
                    <slot v-bind="{rows, options}">
                        <template v-for="(row, index) in rows">
                            <slot name="row-form" v-bind="{ options, row }" v-if="row.editing" />

                            <component
                                v-else
                                :is="rowTag"
                                :class="['row', row._crudTableHighlighted ? 'is-light-red' : '']"
                                :key="row.id"
                                v-bind:href="rowURL(row)"
                                v-bind:target="target"
                                @click="$emit('rowClicked', row)"
                            >
                                <div class="cell is-icon-cell is-relative" v-if="checkable">
                                    <input type="checkbox" v-model="checkedRows" :value="row" />
                                </div>
                                <template v-for="col in visibleCols">
                                    <template v-if="!col.hideContent">
                                        <div
                                            v-if="slotExists(col.key)"
                                            :key="`${row.id}-${col.key}`"
                                            class="cell"
                                            :class="col.class"
                                            :style="col.style"
                                            :data-v-step="`table:cell:${col.key}:${index}`"
                                        >
                                            <slot :name="col.key" v-bind="{ row, options }" />
                                        </div>

                                        <div
                                            v-else-if="componentExists(col.component) "
                                            :key="`${row.id}-${col.key}`"
                                            class="cell"
                                            :class="col.class"
                                            :style="col.style"

                                            :data-v-step="`table:cell:${col.key}:${index}`"
                                        >
                                            <component
                                                :is="col.component"
                                                :row="row"
                                                :value="get(row, col.key)"
                                                :options="options"
                                            />
                                        </div>
                                        <div
                                            v-else-if="col.noHtml"
                                            v-bind:key="`${row.id}-${col.key}`"
                                            class="cell"
                                            v-bind:class="col.class"
                                            :style="col.style"
                                            :data-v-step="`table:cell:${col.key}:${index}`"
                                            v-bind:title="colValue(row, col.key) | stripHtml"
                                        >{{colValue(row, col.key) | stripHtml }}</div>
                                        <div
                                            v-else
                                            v-bind:key="`${row.id}-${col.key}`"
                                            v-html="colValue(row, col.key)"
                                            class="cell"
                                            v-bind:class="col.class"
                                            :style="col.style"
                                            :data-v-step="`table:cell:${col.key}:${index}`"
                                            v-bind:title="colValue(row, col.key)"
                                        ></div>
                                    </template>

                                </template>
                                <slot name="extraCols" v-bind:row="row" ></slot>

                                <div class="cell is-actions" v-if="hasActions" :data-v-step="`table:cell:actions:${index}`">
                                    <slot name="actions" v-bind:row="row" ></slot>

                                    <update-popover
                                        v-if="showUpdate"
                                        v-bind:update="{updatedAt: row.updatedAt, updatedBy: row.updatedBy}"
                                        :api="'api/' + api + '/' + row.id"
                                    >
                                        <button
                                            class="btn-edit"
                                            title="Letzte Aktualisierung"
                                        ><i class="material-icons">update</i></button>
                                    </update-popover>

                                    <button
                                        v-if="hasChildAction"
                                        class="btn-edit"
                                        v-on:click.prevent.stop="handleChildAction(row)"
                                    ><i class="material-icons is-pointer" >add_circle &nbsp;</i></button>

                                    <button
                                        v-if="isEditable"
                                        class="btn-edit"
                                        v-on:click.prevent.stop="handleEdit(row)"
                                    ><i class="material-icons">edit &nbsp;</i></button>

                                    <button
                                        v-if="deleteRow"
                                        class="btn-delete"
                                        v-on:click.prevent.stop="handleDelete(row)"
                                    ><i class="material-icons">delete</i></button>
                                </div>
                            </component>

                            <slot name="subrow" v-bind="{options, row}" />
                        </template>
                    </slot>
                </div>

                <div class="row" v-else>
                    <div class="cell has-text-centered">
                        <template v-if="!load">Noch keine Daten geladen!</template>
                        <template v-else>Keine Einträge</template>
                    </div>
                </div>
                <div v-if="showSum" class="row is-head">
                    <div
                        v-for="col in visibleCols"
                        v-bind:key="col.key"
                        class="cell"
                        v-bind:class="{
                            [col.class]:     !!col.class
                        }"
                        :style="col.style"
                    >
                        <span v-if="col.showSum==='price'">{{calculateSum(col)}}</span>
                        <span v-else-if="col.showSum">{{col.showSum}}</span>
                    </div>


                    <div class="cell is-actions" v-if="isEditable || deleteRow || hasActions">
                        <i class="material-icons is-pointer" v-if="isAddable" v-on:click.prevent.stop="handleAdd">add_circle</i>
                    </div>
                </div>
                <slot name="extraRows"></slot>
            </template>
        </div>

        <!-- Footer -->
        <div class="redesign-table__footer" v-bind:class="{'py-3 is-justify-content-center': simplePagination }" v-if="!isLoading && footer">
            <div>
                <slot name="footer" v-bind:checked="value" v-bind:options="options">
                    <a
                        v-if="isAddable"
                        v-on:click="handleAdd"
                        class="button is-primary"
                    >{{ name.singular | capitalize }} hinzufügen</a>
                </slot>
            </div>
            <pagination v-model="pagination" v-on:input="refresh" :simple="simplePagination"/>
        </div>


        <!-- Form slot -->
        <slot name="form" v-bind:options="options" />
    </div>
</template>



<script>
import axios    from 'axios';
import moment   from 'moment';

import isString from 'lodash/isString'
import get      from 'lodash/get'
import omit     from 'lodash/omit'
import pickBy   from 'lodash/pickBy'
import has      from 'lodash/has';
import replace  from 'lodash/replace';

import Loading from '@components/Loading';
import { notifySuccess, notifyError } from '@components/Notification';
import {
    keyToComponent,
    getStorageFilter,
    setStorageFilter,
    capitalize,
    stripHtml,
    priceView
} from '@utilities/functions';
import { TABLE_LABELS } from '@utilities/variables';

import Filters    from './Filters'
import Cells      from './Cells'
import Pagination from '../Pagination';
import UpdatePopover from "@components/UpdatePopover";
import {settings} from "@clientSpecific/utilities/settings";


export default {
    components: { Filters, ...Cells, Pagination, Loading, UpdatePopover },

    props: {
        value: {
            type: Array,
        },

        api: {
            type: String,
            required: true
        },

        url: {
            type: [Boolean, String, Function],
            default: false
        },

        isZebra: {
            type: [Boolean],
            default: false
        },

        label: {
            type: String,
        },

        columns: {
            type: Array,
            required: true
        },

        name: {
            type: Object,
            default: () => {
                return {
                    singular: "Item",
                    plural: "Items"
                }
            }
        },

        deleteRow: {
            type: Boolean,
            default: true
        },

        softDelete: {
            type: Boolean,
            default: false
        },

        filter: {
            type: Object,
            default: () => {
                return {}
            }
        },

        storeKey: {
            type: [Boolean, String],
            default: false
        },

        load: {
            type: Boolean,
            default: true
        },

        loadOptions: {
            type: [Boolean, String],
            default: true
        },

        passedOptions: {
            type: Object,
            default: () => {}
        },

        target: {
            type: String,
            default: '_self'
        },

        order: {
            type: Object,
            default: () => {}
        },

        passedOrderDirection: {
            type: String,
            default: 'ASC'
        },

        footer: {
            type: Boolean,
            default: true
        },

        checkable: {
            type: Boolean,
            default: false
        },

        showUpdate: {
            type: Boolean,
            default: false
        },

        perPage: {
            type: Number,
            default: 0
        },

        hideFilterRow: {
            type: Boolean,
            default: false
        },

        simplePagination: {
            type: Boolean,
            default: false
        },

        hasChildAction:{
            type:Boolean,
            default:false,
        },

        tableExport : {
            type: Object,
            default: null
        },

        filterKeys: {
            type: Array,
            default: () => [],
        },

        storeFilter: {
            type: Boolean,
            default: true,
        }
    },

    data: function () {
        return {
            isLoading: false,

            rows: [],
            options: this.passedOptions ? this.passedOptions : {},

            filters: {},

            orderBy: this.order ? Object.keys(this.order)[0].split('[')[1].replace(']', '') : null,
            orderDirection: this.passedOrderDirection,

            pagination: {
                current: 1,
                perPage: this.perPage ? this.perPage : (has(settings.pagination, this.api) ? settings.pagination[this.api].perPage : 20),
                items: 0
            },
            cancelSource: null
        }
    },

    mounted: function () {
        this.refresh();
        // this.$layout.setPageName(capitalize(this.name.plural))
    },

    computed: {
        extended() {
            return this.orderBy ? this.orderBy.includes('()') : false
        },

        _groups: function () {
            if (this.api.includes('placeholders/find_extended/get')) {
                return ['user_dashboard_read']
            }
            if (this.api.includes('_requests')) {
                return ['user_dashboard_read']
            }

            return [
                ...(this.columnKeys.includes('totalClientPayments.formatted') ? ['order:revenues', 'money_read'] : []),
                ...(this.columnKeys.some(key => key.includes('placeholder.startAt')) ? ['order_placeholder_read'] : []),
                ...(this.columnKeys.includes('dataCheckedAt') ? ['data_check_read'] : []),
                ...(this.columnKeys.includes('requestSourceArray') && this.api !== 'orders/user_dashboard' ? ['sales_dashboard'] : []),
                ...(this.columnKeys.includes('translations') ? ['translations'] : []),
                ...(this.columnKeys.includes('mainUser') ? ['user_read'] : []),
                ...(this.columnKeys.includes('mainUsers') ? ['user_read'] : []),
                ...(this.columnKeys.some(key => ['client.companyName', 'order.client.companyName', 'day.order.client.companyName', 'order.client.shortName', 'day.order.client.shortName', 'client.shortName'].includes(key))  && this.api !== 'orders/user_dashboard' ? ['client_read'] : []),
                ...(this.api === 'predefined_free_text_items' ? ['predefined_free_text_item:location_destination'] : []),
                ...(this.api === 'order_concepts' ? ['concept_order:destinations', 'order_list_read', 'concept_order:days', 'tag_trait'] : []),
                ...(this.api === 'orders' ? ['order_list_read', 'order_contingent_provider_read', 'order_contingent_item_read', 'order_contact_read', 'contact_read'] : []),
                ...(this.api === 'other_services' ? ['provider_read', 'other_service_type_read', 'place_read', 'area_read', 'destination_read'] : []),
                ...(this.api === 'email_templates' ? ['email_template:email_variant','email_variant'] : []),
                ...(['hotels', 'others', 'ferries', 'trains', 'airlines'].includes(this.api) ? ['provider_property'] : []),
                ...(this.columnKeys.includes('mostUrgentTodo.dueDate')  && this.api !== 'orders/user_dashboard' ? ['todo_read'] : []),
                ...(this.columnKeys.some(key => (key.includes('order.id')  || key.includes('order.orderNumber'))) ? ['order_read', 'service_invoice_payment_read'] : []),
                ...(this.columnKeys.some(key => key.includes('order.currentStatus')) ? ['order_status_read'] : []),
                ...(this.columnKeys.some(key => key.includes('trip'))  && this.api !== 'orders/user_dashboard' ? ['trip_read'] : []),
                ...(this.columnKeys.some(key => key.includes('trip.destinations'))  && this.api !== 'orders/user_dashboard' ? ['destination_read'] : []),
                ...(this.columnKeys.includes('destination.name') ? ['destination_read'] : []),
                ...(this.columnKeys.includes('agencies') ? ['agency_read_list'] : []),
                ...(this.columnKeys.includes('serviceProvider') ? ['agency_read_list'] : []),
                ...(this.columnKeys.includes('area.name') ? ['area_read'] : []),
                ...(this.columnKeys.some(key => ['order.hotelContingent', 'order.ferryContingent'])  && this.api !== 'orders/user_dashboard' ? ['order_contingent_provider_read'] : []),
                ...(this.columnKeys.some(key => key.includes('day.order')) ? ['order_day:order'] : []),
                ...(this.columnKeys.some(key => ['roomType', 'cabinType'].includes(key)) ? ['order_contingent_item_read'] : []),
                ...(this.columnKeys.includes('ports') ? ['port_read', 'place_read'] : []),
                ...(this.columnKeys.includes('place.name') ? ['place_read'] : []),
                ...(this.columnKeys.includes('hotelType') ? ['hotel_type_read'] : []),
                ...(this.columnKeys.includes('provider.name') ? ['provider_read'] : []),
                ...(this.columnKeys.includes('otherServiceType') ? ['other_service_type_read'] : []),
                ...(this.columnKeys.includes('providers') && this.api === 'other_service_types' ? ['other_service_type_provider_read'] : []),
                ...(this.columnKeys.includes('order.totalExpectedRevenues.numberFormatted') ? ['client_invoice:calculations'] : []),
                ...(this.columnKeys.includes('favicon')  && this.api !== 'orders/user_dashboard' ? ['organisation'] : []),
            ]
        },

        hasSlotFilters: function () { return this.$scopedSlots.filters },
        hasActions:     function () { return !!this.$scopedSlots.actions || this.isEditable || !!this.deleteRow },
        hasHeader:      function () { return !!this.$slots.header },
        isEditable:     function () { return !!this.$listeners.edit },
        isAddable:      function () { return !!this.$listeners.add },
        hasLabel:       function () { return !!this.label },

        columnKeys: function () { return this.visibleCols.map(col => col.key) },
        rowTag: function () { return !!this.url ? 'a' : 'div' },

        cols: function () {
            return this.columns.map(column => {
                if (isString(column)) {
                    return {
                        key: column,
                        label: this.getLabel(column),
                        sortable: true,
                        component: keyToComponent(column),
                        class: '',
                        visible: true,
                    }
                } else {
                    return {
                        sortable: true,
                        label: this.getLabel(column.key),
                        component: keyToComponent(column.cellKey ? column.cellKey : column.key),
                        class: '',
                        visible: true,
                        ...column
                    }
                }
            })
        },

        visibleCols: function() {
            return this.cols.filter(col => col.visible)
        },

        filterParams: function () {
            const oneYearBefore = moment().subtract(1, 'year').format('DD.MM.YYYY');

            // Special cases - checkedAt, _search
            let filters = Object.assign({}, omit(this.filters, ['checkedAt', '_search', 'days', 'months', 'ports']), {
                ...(!!this.filters['checkedAt'] && this.filters['checkedAt'] === 'true')  && { 'dataCheckedAt[after]':  oneYearBefore},
                ...(!!this.filters['checkedAt'] && this.filters['checkedAt'] === 'false') && { 'dataCheckedAt[before]':  oneYearBefore},
                ...(!!this.filters['_search'] && this.filters['_search'].value && { [this.searchKey] : this.filters['_search'].value }),
                ...(this.filters['currentStatus.status'] && this.passedOptions && this.passedOptions['orderStatus'] && this.filters['currentStatus.status'].length === 0 && { 'currentStatus.status' : this.options.orderStatus.map(status => status.key)})
            }, this.filter);

            filters = pickBy(filters, (value => value !== '' && value !== null));

            // Days filter
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                .forEach(dayName => {
                    if (has(filters, dayName)) {
                        if (filters[dayName]) {
                            filters[dayName] = 1
                        } else {
                            delete filters[dayName]
                        }
                    }
                });

            // Nested objects
            Object.keys(filters).forEach(key => {
                if (typeof filters[key] === 'object' && !Array.isArray(filters[key])) {
                    Object.keys(filters[key]).forEach(subKey => {
                        filters[`${key}.${subKey}`] = filters[key][subKey];
                    });

                    delete filters[key];
                }
            });

            return filters;
        },

        getParams: function() {
            const self = this;

            const orderBy = (this.api === 'ferry_preset_prices') ? 'priceGroup' : this.orderBy;


            let order = null;
            if(this.order && this.order['_order[' + orderBy + ']']) {
                let order = JSON.parse(JSON.stringify(this.order));
                let orderDirection = this.orderDirection;
                Object.keys(order).forEach(function(key, index) {
                    if(!order[key]) {
                        order[key] = orderDirection;
                    }
                });
            }


            const orderParams = {
                ...order,
                [`_${this.extended ? 'extendedOrder' : 'order'}[${orderBy}]`] : this.orderDirection,
            };
            return {
                _groups: this._groups,
                _page: this.pagination.current,
                _itemsPerPage: this.pagination.perPage,
                ...this.extended && {
                    _itemsPerPage: 99999,
                    _page: 1,
                    _extendedItemsPerPage: this.pagination.perPage,
                    _extendedPage: this.pagination.current,
                },
                ...!!orderBy && orderParams,
                ...this.filterParams
            }
        },

        searchKey: function () {
            const searchable = this.cols.filter(col => col.searchable);

            if (searchable.length > 0) {
                return searchable[0].key;
            }

            return '_search';
        },

        _storeKey: function () {
            return this.storeKey || this.api
        },

        filterAPI: function () {
            return `${this.api}-${JSON.stringify(this.filter)}`
        },

        subrowsVisible: function () {
            return this.rows.filter(row => row.subrowVisible).length === this.rows.length
        },

        showSum: function () {
            return this.cols.filter(col => col.showSum).length > 0;
        },

        optionsAPI: function () {
            return typeof this.loadOptions === 'string'
                ? `/api/${this.loadOptions}`
                : `/api/${this.api}`
        },

        checkedRows: {
            get: function() { return this.value },
            set: function(rows) { this.$emit('input', rows) }
        },

        checkAll: {
            get: function() { return this.row ? this.rows.length == this.value.length : false },
            set: function(value) { this.$emit('input', value ? JSON.parse(JSON.stringify(this.rows)) : []) }
        },

        loadingText: function() {
            if (typeof this.isLoading === 'object') {
                return this.isLoading
            } else {
                return {}
            }
        }
    },

    created: function () {
        this.buildFilters();

        if (this.loadOptions) {
            this.fetchOptions()
                .then(options => {
                    this.$emit('loaded', {options})
                })
        }
    },


    methods: {
        get,

        calculateSum: function(col) {
            let amount = 0;
            this.rows.forEach(row => {
                if(this.colValue(row,col.key)){

                    amount += parseFloat(replace(replace(this.colValue(row,col.key), '.', ''), ',', '.'));
                }
            })
            return priceView(amount);
        },

        cancelSearch () {
            if (this.cancelSource) {
                this.cancelSource.cancel('Axios canceled');
            }
        },
        fetchRows: function () {
            this.isLoading = true;

            const api = this.api.includes('placeholders/find_extended/get')
                ? this.api.replace('/find_extended/get', '')
                : (this.extended ? `${this.api}/extended/get` : this.api);

            this.cancelSearch();
            this.cancelSource = axios.CancelToken.source();

            return new Promise((resolve, reject) => {
                axios.get(`/api/${api}`, {
                    params: this.getParams,
                    cancelToken: this.cancelSource.token
                }).then(response => {

                    this.rows = response.data.map(item => {
                        let highlight = false;
                        this.columns.forEach(col => {
                            if(col.crudHighlightArray && col.crudHighlightArray.includes(this.colValue(item, col.key))) {
                                highlight = true;
                            }
                        })
                        return {
                            ...item,
                            subrowVisible: false,
                            editing: false,
                            _crudTableHighlighted: highlight,
                        }
                    });
                    this.cancelSource = null;



                    this.pagination = {
                        ...this.pagination,
                        items: Number(response.headers['x-trav-total-items']),
                    };

                    this.isLoading = false;
                    this.$emit('dataLoaded', response.data);
                    resolve(response.data)
                }, error => {});
            })
        },

        addRow: function () {
            if (!this.rows.some(row => row.editing)) {
                this.rows.push({
                    editing: true
                })
            }
        },

        toggleRow: function (toggleRow) {
            this.rows = this.rows.map(row => {
                if (row.id === toggleRow.id) {
                    return {
                        ...row,
                        subrowVisible: !row.subrowVisible
                    }
                } else {
                    return row;
                }
            })
        },

        toggleRows: function () {
            this.rows = this.rows.map(row => ({...row, subrowVisible: !this.subrowsVisible }))
        },

        cancelEditing: function () {
            this.rows = this.rows
                .filter(row => has(row, 'id'))
                .map(row => {
                    return {...row, editing: false}
                })
        },

        fetchOptions: function () {
            const self = this;

            return new Promise((resolve, reject) => {
                axios.options(self.optionsAPI)
                    .then(response => {
                        self.options = {...response.data, ...this.passedOptions};

                        resolve(response.data)
                    }, error => {})
            })
        },

        buildFilters: function () {
            const _search = this.storeFilter ? getStorageFilter(this._storeKey, '_search') : '';
            this.filters =  {};
            this.filters['_search'] = {
                value: !!_search ? _search.value : '',
                key: this.searchKey
            };

            this.cols
                .filter(col => col.filter)
                .forEach(col => {
                    if (isString(col.filter)) {
                        this.filters[col.filter] = getStorageFilter(this._storeKey, col.filter);
                    } else {
                        this.filters[col.filter.key] = getStorageFilter(this._storeKey, col.filter.key, has(col.filter, 'default') ? col.filter.default : '')
                    }
                })

            this.filterKeys.forEach(key => {
                if (isString(key)) {
                    this.filters[key] = getStorageFilter(this._storeKey, key);
                } else {
                    this.filters[key.key] = getStorageFilter(this._storeKey, key.key, has(key, 'default') ? key.default : '')
                }
            })
        },

        sortBy: function (column) {
            if (column.sortable) {
                const key = isString(column.sortable) ? column.sortable : column.key;
                this.orderDirection = (key === this.orderBy) ? (this.orderDirection === 'ASC' ? 'DESC' : 'ASC') : 'DESC';
                this.orderBy = key;
                this.refresh();
            }
        },

        refresh: function (loadOptions = false) {
            if (this.load) {
                this.fetchRows();

                if (this.loadOptions && loadOptions) {
                    this.fetchOptions();
                }
            }
        },

        handleAdd: function () {
            this.$emit('add');
        },

        handleEdit: function (row) {
            this.$emit('edit', row);
        },

        handleChildAction: function (row){
            this.$emit('childAction',row)
        },

        handleDelete: function (item) {
            if (!!this.$listeners.delete) {
                this.$emit('delete', item)
            } else if (confirm(`Bist du sicher dass du das Element löschen willst?`)) {

                this.callDeleteApi(item)
                    .then(data => { // Success
                        this.refresh();
                        notifySuccess('Das Element wurde gelöscht!');

                    }, error => { // Failed
                        notifyError('Das Element konnte nicht gelöscht werden!');
                    });
            }
        },

        callDeleteApi: function (item) {
            return this.softDelete ? axios.put(`/api/${this.api}/${item.id}/delete`, {deleted: true}) : axios.delete(`/api/${this.api}/${item.id}`);
        },

        // Getters
        componentExists: function (component) {
            return !!this.$options.components[component];
        },

        slotExists: function (key) {
            return !!this.$scopedSlots[key]
        },

        colValue: function (row, key) {
            const value = get(row, key);

            if (Array.isArray(value)) {
                return value.map(v => v.name).join(', ');
            }

            return value;
        },

        getLabel: function (key) {
            if (has(TABLE_LABELS, key)) {
                return TABLE_LABELS[key]
            } else {
                return key;
            }
        },

        rowURL: function (row) {
            if (this.url) {
                if (typeof this.url === 'function') {
                    return this.url(row);
                } else {
                    return `${this.url}/${row.id}`
                }

            } else {
                return '#'
            }
        },

        setLoading: function(value) {
            this.isLoading = value
        }
    },


    watch: {
        filters: {
            deep: true,
            handler: function (filters) {
                this.pagination.current = 1;
                this.$emit('changeFilters', filters);
                if(this.storeFilter) {
                    setStorageFilter(this._storeKey, filters);
                }
            }
        },

        filterAPI: {
            handler: function () {
                this.pagination.current = 1;
                this.refresh()
            }
        },
        columns () {
            this.buildFilters();
        }
    },


    filters: {
        capitalize,
        stripHtml
    }
}
</script>
