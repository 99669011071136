<template>
    <modal-form
        editTitle="Bankverbindung editieren"
        createTitle="Neue Bankverbindung"
    >
        <table>
            <tr>
                <th><label for="accountType" class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="accountType" id="accountType" @input="changeAccountType">
                        <option value="iban">IBAN</option>
                        <option value="swift">SWIFT</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="accountType === 'iban'">
                <th><label for="iban" class="label">IBAN:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="iban" class="input" v-model="form.iban" @blur="getBankInformation(form.iban)">
                    </div>
                </td>
            </tr>

            <tr v-if="accountType === 'swift'">
                <th><label for="foreignAccountNumber" class="label">Konto-Nr:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="foreignAccountNumber" class="input" v-model="form.foreignAccountNumber">
                    </div>
                </td>
            </tr>

            <tr v-if="accountType === 'iban'">
                <th><label for="bic" class="label">BIC:</label></th>
                <td>
                  <div class="field has-addons">
                    <div class="control is-expanded">
                      <input type="text" id="bic" class="input" v-model="form.bic" :disabled="bankDataDisabled">
                    </div>
                    <span class="control ml-2">
                        <i class="material-icons is-pointer" @click="bankDataDisabled = !bankDataDisabled">edit</i>
                    </span>
                  </div>

                </td>
            </tr>

            <tr v-if="accountType === 'swift'">
                <th><label for="swift" class="label">Swift Code:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="swift" class="input" v-model="form.swift">
                    </div>
                </td>
            </tr>

            <tr>
              <th><label for="bic" class="label">Bank:</label></th>
              <td>
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input type="text" id="bank" class="input" v-model="form.bank" :disabled="bankDataDisabled">
                  </div>
                  <span class="control ml-2">
                          <i class="material-icons is-pointer" @click="bankDataDisabled = !bankDataDisabled">edit</i>
                      </span>
                </div>

              </td>
            </tr>


            <tr>
                <th><label for="accountHolder" class="label">Kontoinhaber:</label></th>
                <td>
                    <div class="control">
                        <input type="text" id="accountHolder" class="input" v-model="form.accountHolder">
                    </div>
                </td>
            </tr>

            <tr>
                <th class="is-top"><label for="notes" class="label">Zusätzliche Notizen:</label></th>
                <td>
                    <div class="control">
                        <tip-tap v-model="form.notes" />
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="primaryAccount" class="label">Primärkonto:</label></th>
                <td>
                   <div class="control">
                       <label class="radio">
                            <input type="radio" v-bind:value="true" v-model="form.primaryAccount">ja
                       </label>
                       <label class="radio">
                            <input type="radio" v-bind:value="false" v-model="form.primaryAccount">nein
                       </label>
                    </div>
                </td>
            </tr>
        </table>
    </modal-form>
</template>

<script>
import { TipTap } from '@components/form'
import TabForm from '../form';
import axios from "axios";
import ToggleSwitch from "@components/form/ToggleSwitch.vue";

export default {
    mixins: [TabForm],

    form: {
        iban: '',
        bic: '',
        bank: '',
        foreignAccountNumber: null,
        swift: null,
        accountHolder: '',
        notes: '',
        primaryAccount: true,
    },

    data () {
        return {
            accountType: 'iban',
            bankDataDisabled: true,
        }
    },

    property: 'bankAccounts',

    methods: {
        changeAccountType (input) {
            if(input === 'swift') {
                this.form.iban = null;
                this.form.bic = null;
                this.bankDataDisabled = false;
            } else {
                this.form.swift = null;
                this.form.foreignAccountNumber = null;
                this.bankDataDisabled = true;
            }
        },

        openCallback () {
            if(this.form.swift) {
                this.accountType = 'swift';
                this.bankDataDisabled = false;
            } else {
                this.accountType = 'iban';
                this.bankDataDisabled = true;
            }
        },

        getBankInformation (iban) {
          if(iban) {
            axios('https://openiban.com/validate/' + iban + '?getBIC=true&validateBankCode=true').then(response => {
              if(response.data.valid && response.data.bankData) {
                  this.form.bic = response.data.bankData.bic;
                  this.form.bank = response.data.bankData.name;
              }
            }, error => {
              this.bankDataDisabled = false;
            })
          }
        }
    },

    components: {
        ToggleSwitch,
        TipTap
    },
}
</script>
