<template>
    <div>
<!--        <edit-table
            ref="table"
            :rows="contacts"
            :passed-options="options"
            :load-options="false"
            @saveRow="saveRow"
            :row-classes="['not-white']"
            @edit="openForm"
            v-bind:columns="columns"
            v-bind:name="{singular: 'Leistung', plural: 'Leistungen' }"


        >
        </edit-table>-->
        <crud-table
            ref="table"

            api="contacts"
            :soft-delete="true"


            label="Kontakte"
            :name="{singular: 'Kontakt', plural: 'Kontakte'}"

            v-on:add="openForm"
            v-on:edit="openForm"
            @loaded="opt => $store.state.options.contacts = opt.options"


            :filter="params"


            :columns="columns"
        >
            <h2 slot="header">Kontakte</h2>



            <template v-slot:title="slotProps">
                {{titles.find(title => title.key === slotProps.row.title) ? titles.find(title => title.key === slotProps.row.title).value : ''}}
            </template>

            <template v-slot:role="slotProps">
                {{roles.find(title => title.key === slotProps.row.role) ? roles.find(title => title.key === slotProps.row.role).value : ''}}
            </template>

            <template v-slot:type="slotProps">
                <i
                    v-if="slotProps.row.type === 'primary'"
                    class="material-icons"
                >check</i>
            </template>


            <item-form
                slot="form"
                slot-scope="{ options }"
                formType="modal"
                ref="form"
                v-on:success="$refs.table.refresh()"
            />

            <template v-slot:footer>
                <div class="level level-left">
                    <a
                        v-on:click="openForm"
                        class="button level-item is-primary"
                    >Kontakt hinzufügen</a>
                </div>

            </template>
        </crud-table>
    </div>

</template>

<script>
import Mixin             from '../tab';
import NewEmail          from '@components/emails/modals/newEmail/index';
import { languages }     from '@utilities/variables';
import { phone }         from '@utilities/functions';
import {getAxios} from "@api";
import EditTable from "@components/EditTable/Index.vue";
import ItemForm from './Form';
import {CrudTable} from "@components/CRUD";

export default {
    mixins: [Mixin],
    data: function () {
        return  {

            newEmailContact: null,
            isNewEmailVisible: false,
            columns: [
                { key: 'title', label:  'Titel', class: 'has-border is-60px-cell is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'firstName', label:  'Vorname', class: 'has-border is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', formElement: 'PureText' },
                { key: 'lastName', label:  'Nachname', class: 'has-border is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', formElement: 'PureText' },
                { key: 'role', label:  'Position', class: 'has-border is-150px-cell is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', formElement: 'PureText' },
                { key: 'email', label:  'Email', class: 'has-border is-pure-text is-200px-cell is-ellipsis', style: 'flex-grow:1;flex-shrink:1', formElement: 'PureText' },
                { key: 'phoneFixed', cellKey: 'phone', label:  'Telefon', class: 'has-border is-110px-cell is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'phoneMobile', cellKey: 'phone', label:  'Mobil', class: 'has-border is-110px-cell is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'fax', label:  'Fax', class: 'has-border is-110px-cell is-pure-text is-ellipsis', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'languages', cellKey: 'flags', label:  'Sprache', class: 'has-border is-90px-cell is-pure-text is-ellipsis has-text-centered', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'newsletter', cellKey: 'checkedCatalogText', label:  'NL', class: 'has-border is-icon-cell is-ellipsis has-text-centered', style: 'flex-grow:1;flex-shrink:1', sortable: false },
                { key: 'type', label:  'Primär', class: 'has-border is-icon-cell is-ellipsis has-text-centered', style: 'flex-grow:1;flex-shrink:1', sortable: false },
            ],
        }
    },

    computed: {
        roles: function () { return this.options && this.options.contacts ? this.options.contacts.role : []},
        titles: function () { return this.options && this.options.contacts ? this.options.contacts.title : []},
        contacts: {
            get () {
                return this.item.contacts
            }
        },
        params () {
            if(this.api === 'clients') {
                return {'client.id': this.item.id};
            } else if(this.api === 'agencies') {
                return {'agency.id': this.item.id};
            } else {
                return {'provider.id': this.item.id};
            }
        }
    },


    methods: {
        saveRow () {

        },

        isGeneral: function (item) {
            return item.role === 'general_contact';
        },



        faxURL: function(value) {
            return `fax:${phone(value)}`
        },
    },

    activated: function () {
        /*this.isLoading = true;
        let filter = null;
        if(this.api === 'clients') {
            filter = {'client.id': this.item.id};
        } else if(this.api === 'agencies') {
            filter = {'agency.id': this.item.id};
        } else {
            filter = {'provider.id': this.item.id};
        }
        getAxios('contacts', {
            _groups: ['address_read', 'contact_read_relations'],
            ...filter
        }).then(response => {
            this.$store.commit('updateData', {
                contacts: response.data
            })
        }).then(() => {
            this.isLoading = false;
        })*/
    },


    components: {
        CrudTable,
        ItemForm,
        EditTable,
        NewEmail,
    }
}
</script>
