<template>
    <div class="sb-wrapper">
        <header>
            <h1>Dein Travelize AI Assistent</h1>
            <button class="close" v-on:click.prevent.stop="$emit('hideSideBar')" title="Schließen"><i class="material-icons">close</i></button>
        </header>
        <div class="sb-content">
            <ai-message :is-user="false">
                Hallo {{currentUser.firstName}}! Ich sehe du möchtest einen Anbieter hinzufügen. Hier eine Übersicht über Anbieter in Vorgängen:
            </ai-message>
            <crud-table
                ref="table"
                :key="api"
                v-bind:api="api"
                v-bind:url="rowURL"
                target="_blank"
                :simplePagination="true"
                v-bind:deleteRow="false"
                :filter="params"
                @loaded="saveOptions"

                loadOptions="orders"

                v-bind:columns="columns"
                v-bind:name="{singular: 'Element', plural: 'Elementen' }"
            >
                <template v-slot:provider="slotProps">
                    <span v-if="placeholder.placeholderType === 'hotel'">{{ options.boardList ? options.boardList.find(board => board.key === placeholder.board).value.replace('+', '') : placeholder.board }}: </span>
                    <span v-if="slotProps.row.agency" :title="slotProps.row.agency.name">[A] </span>
                    <span v-if="slotProps.row.provider" :title="slotProps.row.provider.name">{{slotProps.row.provider.name}}</span>
                    <span v-if="slotProps.row.agency && !slotProps.row.provider" :title="slotProps.row.agency.name">No Name - {{slotProps.row.agency.name}}</span>
                </template>
                <template v-slot:requestStatus.status="slotProps">
                    <span>{{options.requestStatuses ? options.requestStatuses.find(status => status.key === slotProps.row.requestStatus.status).value : slotProps.row.requestStatus.status}}</span>
                </template>
            </crud-table>
        </div>
    </div>
</template>
<script>


import AiMessage from "@components/AiChat/AiMessage.vue";
import Loading from "@components/Loading.vue";


import {currentUser} from "@utilities/functions";
import {CrudTable} from "@components/CRUD";

export default {

    props: {
        sidebarData: {
            type: Object,
        }
    },

    data: function() {
        return {
            rowURL: null,
            options: {}
        }
    },

    computed: {
        api () {
            return this.placeholder.placeholderType + '_requests'
        },


        placeholder () {
            return this.sidebarData.conceptProvider.placeholder;
        },

        params () {
            return {
                ...this.placeholder.placeholderType === 'ferry' && {
                    'placeholder.startPort.id': this.placeholder.startPort.id,
                    'placeholder.endPort.id': this.placeholder.endPort.id
                },
                ...this.placeholder.placeholderType === 'hotel' && {
                    'placeholder.area.id': this.placeholder.area.id,
                },
                ...this.placeholder.placeholderType === 'other' && {
                    'placeholder.otherServiceType.id': this.placeholder.otherServiceType.id,
                }
            }
        },

        columns () {
            return [
                {
                    label: 'Anbieter',
                    sortable: false,
                    class: 'is-relative',
                    style: 'flex-grow: 1; flex-shrink: 1; overflow: hidden; width: 50%',
                    key: 'provider'
                },
                { key: 'placeholder.startAt', class: 'is-date-cell is-relative', label: 'Datum', sortable: false},
                { key: 'requestStatus.status', filter: 'requestStatus.status', style: 'min-width: 70px', label: 'Status', crudHighlightArray: ['called_off', 'declined', 'canceled'], class: 'is-relative', sortable: false },
                { key: 'info.standardPrice.numberFormatted', label: 'Preis', class: 'is-relative', sortable: false, style: 'width: 65px' },
            ]
        }
    },

    methods: {
        currentUser,

        saveOptions (data) {
            this.options = data.options;
        }
    },

    components: {
        CrudTable,
        Loading,
        AiMessage,

    }
}

</script>
