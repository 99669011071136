<template>
    <a :href="telURL(value)" v-if="value">{{value}}</a>
</template>

<script>
import Cell from './cell';
import {phone} from "@utilities/functions";

export default {
    mixins: [ Cell ],

    methods: {
        telURL: function(value) {
            return `tel:${phone(value)}`
        },
    },
}
</script>

