var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal-form",
        {
          attrs: {
            editTitle: "Hinweis bearbeiten",
            createTitle: "Neuen Hinweis hinzufügen",
          },
          on: { imageUploaded: _vm.handleImageDrop },
        },
        [
          _c(
            "table",
            [
              !_vm.editMode
                ? [
                    _c("tr", [
                      _c("th", [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "type" } },
                          [_vm._v("Art:")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "toggle-switch",
                            {
                              model: {
                                value: _vm.form.tripDetailType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "tripDetailType", $$v)
                                },
                                expression: "form.tripDetailType",
                              },
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("nur Hinweis"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.tripDetailTypesFiltered,
                                function (name, value) {
                                  return _c(
                                    "option",
                                    { key: value, domProps: { value: value } },
                                    [_vm._v(_vm._s(name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("th", { staticClass: "is-top" }, [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "images" } },
                    [_vm._v("Bilder (540 x 250):")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "image-upload",
                      {
                        attrs: { multiple: "", label: "Bild hochladen" },
                        model: {
                          value: _vm.form.images,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "images", $$v)
                          },
                          expression: "form.images",
                        },
                      },
                      [
                        _c("template", { slot: "buttons" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  _vm.visibleImageMap = true
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("map"),
                              ]),
                              _vm._v(
                                "\n                                Google Map Karte\n                            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.$refs.imageSelect.open()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("photo"),
                              ]),
                              _vm._v(
                                "\n                                Bild aus Stammdaten\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isNotDestination
                ? _c("tr", [
                    _c("th", [
                      _c(
                        "label",
                        { staticClass: "label", attrs: { for: "sections" } },
                        [_vm._v("Anzeigen:")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "toggle-switch",
                          {
                            model: {
                              value: _vm.form.sections,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sections", $$v)
                              },
                              expression: "form.sections",
                            },
                          },
                          _vm._l(_vm.infoSections, function (name, value) {
                            return _c(
                              "option",
                              { key: value, domProps: { value: value } },
                              [_vm._v(_vm._s(name))]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "importance" } },
                    [_vm._v("Wichtigkeit:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("multiselect", {
                      staticClass: "is-medium",
                      attrs: {
                        options: _vm.importances,
                        "track-by": "key",
                        label: "value",
                      },
                      model: {
                        value: _vm.importance,
                        callback: function ($$v) {
                          _vm.importance = $$v
                        },
                        expression: "importance",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              !_vm.item.providerType && _vm.isNotDestination
                ? _c("tr", [
                    _c("th", [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: "form.showInConnectedProviders" },
                        },
                        [_vm._v("Gültigkeit:")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "toggle-switch",
                          {
                            model: {
                              value: _vm.form.showInConnectedProviders,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "showInConnectedProviders",
                                  $$v
                                )
                              },
                              expression: "form.showInConnectedProviders",
                            },
                          },
                          [
                            _c("option", { domProps: { value: false } }, [
                              _vm._v("Nur für Agentur"),
                            ]),
                            _vm._v(" "),
                            _c("option", { domProps: { value: true } }, [
                              _vm._v("Auch für verbundene Anbieter"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [
                  _vm.form.tripDetailType === "provider_info"
                    ? _c("div", { staticClass: "redesign-table" }, [
                        _c("div", { staticClass: "row is-head" }, [
                          _c("div", { staticClass: "cell" }, [_vm._v("Titel")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cell" }, [
                            _vm._v("Hinweis"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row is-h-auto" }, [
                          _c(
                            "div",
                            { staticClass: "cell is-gapless self-stretch" },
                            [
                              _c(
                                "div",
                                { staticClass: "input-wrapper" },
                                [
                                  _c("multiselect", {
                                    ref: "title",
                                    attrs: {
                                      options: _vm.titles,
                                      value: _vm.form.title,
                                      taggable: true,
                                    },
                                    on: {
                                      tag: _vm.addTitle,
                                      close: _vm.closeTitle,
                                      "search-change": (title) =>
                                        (_vm.searchTitle = title),
                                      open: function ($event) {
                                        return _vm.$refs.title.updateSearch(
                                          _vm.form.title
                                        )
                                      },
                                      input: (title) => _vm.addTitle(title),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cell is-gapless" },
                            [
                              _c("resizable", [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.content,
                                      expression: "form.content",
                                    },
                                  ],
                                  staticClass: "textarea",
                                  attrs: { rows: "1" },
                                  domProps: { value: _vm.form.content },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.form,
                                        "content",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "bp-panel" }, [
                        _c("div", { staticClass: "panel-content" }, [
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c("multiselect", {
                                ref: "title",
                                attrs: {
                                  options: _vm.titles,
                                  value: _vm.form.title,
                                  taggable: true,
                                },
                                on: {
                                  tag: _vm.addTitle,
                                  close: _vm.closeTitle,
                                  "search-change": (title) =>
                                    (_vm.searchTitle = title),
                                  open: function ($event) {
                                    return _vm.$refs.title.updateSearch(
                                      _vm.form.title
                                    )
                                  },
                                  input: (title) => _vm.addTitle(title),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "field" },
                            [
                              _c("tip-tap", {
                                staticClass: "is-fullwidth",
                                attrs: {
                                  isNotAlone: "",
                                  menuItems: [
                                    "fullscreen",
                                    "bold",
                                    "italic",
                                    "underline",
                                    "h1",
                                    "h2",
                                    "bullet_list",
                                    "ordered_list",
                                    "link",
                                    "color",
                                    "undo",
                                  ],
                                },
                                model: {
                                  value: _vm.form.content,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "content", $$v)
                                  },
                                  expression: "form.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("translations-field", {
                attrs: {
                  translations: _vm.form.translations,
                  "translation-options": _vm.translationOptions,
                },
                on: { changeTranslation: _vm.changeTranslation },
                model: {
                  value: _vm.locale,
                  callback: function ($$v) {
                    _vm.locale = $$v
                  },
                  expression: "locale",
                },
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("image-select", {
        ref: "imageSelect",
        attrs: { params: { "provider.id": _vm.providerID }, multiple: "" },
        model: {
          value: _vm.form.images,
          callback: function ($$v) {
            _vm.$set(_vm.form, "images", $$v)
          },
          expression: "form.images",
        },
      }),
      _vm._v(" "),
      _vm.visibleImageMap
        ? _c("image-map", {
            on: {
              close: function ($event) {
                _vm.visibleImageMap = false
              },
            },
            model: {
              value: _vm.form.images,
              callback: function ($$v) {
                _vm.$set(_vm.form, "images", $$v)
              },
              expression: "form.images",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }