<template>
    <div>
            <div class="columns pt-0 pb-0 mt-0 mb-0 is-vcentered">
                <div class="column is-narrow pt-1 pb-1">
                    <i class="fa fa-filter fa-lg"></i>
                </div>
                <div class="column pt-0 pb-0">
                    <div class="columns is-multiline  mt-0 mb-0 is-vcentered is-variable is-1 is-mobile has-min-width-150">
                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('_search')">
                            <input
                                v-model="searchValue"
                                v-on:input="updateSearch"
                                type="text"
                                class="input"
                                placeholder="Suchen"
                            >
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('addresses')">
                            <input
                                v-model="cityValue"
                                v-on:input="updateCity"
                                type="text"
                                class="input "
                                placeholder="Ort filtern"
                            >
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('addresses.zip')">
                            <input
                                v-model="addressesZipValue"
                                v-on:input="updateAddressesZip"
                                type="text"
                                class="input "
                                placeholder="PLZ filtern"
                            >
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('organisationFilter.id') && resources.organisations">
                            <multiselect
                                v-bind:options="resources.organisations"
                                v-model="filterOrganisation"
                                class="is-smaller level-item is-shorter"
                                track-by="id"
                                label="name"
                                placeholder="Organisation"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterOrganisation"
                                    v-on:click.prevent.stop="filterOrganisation = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('user.id') || isVisible('mainUser.id') || isVisible('mainUsers.id')">
                            <multiselect
                                v-model="filterUser"
                                class="is-shorter"
                                track-by="id"
                                v-bind:custom-label="userLabel"
                                v-bind:options="resources.users"
                                placeholder="Bearbeiter filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterUser"
                                    v-on:click.prevent.stop="filterUser = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('category') && categoryOptions.length > 0">
                            <multiselect
                                v-model="filterCategory"
                                class="is-shorter"
                                track-by="key"
                                label="value"
                                v-bind:options="categoryOptions"
                                placeholder="Kategorie filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCategory"
                                    v-on:click.prevent.stop="filterCategory = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('ports')">
                            <div class="flatpickr-combined">
                                <async-select
                                    api="ports"
                                    v-model="filterStartPort"
                                    class="is-smaller is-shorter"
                                    v-bind:custom-label="item => item.place.name"
                                    crudLinkTitle="Fügen Sie einen neuen Port hinzu"
                                    placeholder="Port filtern"
                                    v-on:load="portOptions => updateResources({startPorts: portOptions})"
                                />
                                <span>-</span>
                                <async-select
                                    api="ports"
                                    v-model="filterEndPort"
                                    class="is-smaller is-shorter"
                                    v-bind:custom-label="item => item.place.name"
                                    crudLinkTitle="Fügen Sie einen neuen Port hinzu"
                                    placeholder="Port filtern"
                                    v-on:load="portOptions => updateResources({endPorts: portOptions})"
                                />
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('otherServices.otherServiceType.id')">
                            <async-select
                                api="other_service_types"
                                v-model="filterOtherServiceType"
                                class="is-shorter"
                                placeholder="ZL filtern"
                                v-on:load="opt => updateResources({otherServiceTypes: opt})"
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('type') && options.types">
                            <multiselect
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.types"
                                v-model="filterType"
                                placeholder="Art filtern"
                                :multiple="isTypeMultiple"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterType && !isTypeMultiple"
                                    v-on:click.prevent.stop="filterType = ''"
                                ></div>
                                <template v-if="isTypeMultiple" slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Element(e) ausgewählt</span>
                                </template>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('otherServiceType.category') && options.otherServiceTypeCategories">
                            <multiselect
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.otherServiceTypeCategories"
                                v-model="filterOtherServiceTypeCategories"
                                placeholder="Kategorie filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterOtherServiceTypeCategories"
                                    v-on:click.prevent.stop="filterOtherServiceTypeCategories = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="destinationKey">
                            <async-select
                                api="destinations"
                                v-model="filterDestination"
                                class="is-shorter"
                                crudLinkTitle="Neuen Destination hinzufügen"
                                placeholder="Destination filtern"
                                v-on:load="opt => updateResources({destinations: opt})"
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('place.area.id') || isVisible('area.id')">
                            <async-select
                                api="areas"
                                v-model="filterArea"
                                class="is-shorter"
                                crudLinkTitle="Neuen Region hinzufügen"
                                placeholder="Region filtern"
                                v-on:load="opt => updateResources({areas: opt})"
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('place.id')">
                            <async-select
                                api="places"
                                v-model="filterPlace"
                                class="is-shorter"
                                crudLinkTitle="Neuen Ort hinzufügen"
                                placeholder="Ort filtern"
                                v-on:load="opt => updateResources({places: opt})"
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('agencies.id')">
                            <async-select
                                api="agencies"
                                v-model="filterAgencies"
                                class="is-shorter"
                                crudLinkTitle="Neue Agentur hinzufügen"
                                placeholder="Agenturen filtern"
                                v-on:load="opt => updateResources({agencies: opt})"
                                multiple
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('country')">
                            <multiselect
                                track-by="label"
                                label="name"
                                class="is-shorter"
                                v-bind:options="resources.countries"
                                v-model="filterCountry"
                                placeholder="Land filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCountry"
                                    v-on:click.prevent.stop="filterCountry = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('addresses.country')">
                            <multiselect
                                track-by="label"
                                label="name"
                                multiple
                                class="is-shorter"
                                v-bind:options="resources.countries"
                                v-model="filterAddressCountry"
                                placeholder="Land filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCountry"
                                    v-on:click.prevent.stop="filterCountry = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('importance') && options.importance">
                            <multiselect
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.importance"
                                v-model="filterImportance"
                                placeholder="Wichtigkeit filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterImportance"
                                    v-on:click.prevent.stop="filterImportance = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('locale') && options.locale">
                            <multiselect
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.locale"
                                v-model="filterLocale"
                                placeholder="Sprache filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterLocale"
                                    v-on:click.prevent.stop="filterLocale = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('difficulty') && options.difficulties">
                            <multiselect
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.difficulties"
                                v-model="filterDifficulty"
                                placeholder="Schwierigkeit filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterDifficulty"
                                    v-on:click.prevent.stop="filterDifficulty = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('currentStatus.status') && options.orderStatus">
                            <multiselect
                                v-model="filterStatuses"
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.orderStatus"
                                placeholder="Reisestatus filtern"
                                v-bind:close-on-select="false"
                                v-bind:clear-on-select="false"
                                multiple
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
                                </template>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('requestStatus.status') && (options.requestStatuses || options.status)">
                            <multiselect
                                v-model="filterRequestStatus"
                                v-bind:options="options.requestStatuses ? options.requestStatuses : options.status"
                                multiple
                                label="value"
                                track-by="key"
                                placeholder="LT-Status filtern"
                                class="is-shorter"
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} LT-Stati ausgewählt</span>
                                </template>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('status') && options.type">
                            <multiselect
                                v-model="filterClientInvoiceStatuses"
                                track-by="key"
                                label="value"
                                class="is-shorter"
                                v-bind:options="options.type"
                                placeholder="Status filtern"
                                v-bind:close-on-select="false"
                                v-bind:clear-on-select="false"
                                multiple
                            >
                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                    <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Stati ausgewählt</span>
                                </template>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('startAt[after]') && isVisible('startAt[before]')">
                            <div class="flatpickr-combined">
                                <input-date v-model="filterStartAtAfter"></input-date>
                                <span>-</span>
                                <input-date v-model="filterStartAtBefore"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('endAt[after]') && isVisible('startAt[before]')">
                            <div class="flatpickr-combined">
                                <input-date v-model="filterEndAtAfter"></input-date>
                                <span>-</span>
                                <input-date v-model="filterStartAtBefore"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('timePeriods.endAt[after]') && isVisible('timePeriods.startAt[before]')">
                            <div class="flatpickr-combined">
                                <input-date v-model="filterTimePeriodsEndAtAfter"></input-date>
                                <span>-</span>
                                <input-date v-model="filterTimePeriodsStartAtBefore"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('priceGroups.timePeriods.endAt[after]') && isVisible('priceGroups.timePeriods.startAt[before]')">
                            <div class="flatpickr-combined">
                                <input-date v-model="filterPriceGroupsTimePeriodsEndAtAfter"></input-date>
                                <span>-</span>
                                <input-date v-model="filterPriceGroupsTimePeriodsStartAtBefore"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('months')">
                            <div class="flatpickr-combined">
                                <input-month
                                    v-model="filterMonthStartAt"
                                    ref="startAt"
                                    v-on:keyup.native.delete="$refs.startAt.clearDate"
                                />
                                <span>-</span>
                                <input-month
                                    v-model="filterMonthEndAt"
                                    ref="endAt"
                                    v-on:keyup.native.delete="$refs.endAt.clearDate"
                                />
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('agency[exists]')">
                            <div class="level">
                                <toggle-switch  v-model="filterAgencyExists" btn-class="is-120">
                                    <option value="">Alle</option>
                                    <option :value="false">Nur Direktpreise</option>
                                </toggle-switch>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('dateText')">
                            <div class="level">
                                <toggle-switch v-model="filterDateText" btn-class="is-75" style="margin-right: 10px">
                                    <option value="w">1 Woche</option>
                                    <option value="M">1 Monat</option>
                                    <option value="individual">Individuell</option>
                                </toggle-switch>
                                <input-date class="is-date" v-model="filterDateBefore" v-if="filterDateText === 'individual'"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('date[after]') && isVisible('date[before]')">
                            <div class="flatpickr-combined">
                                <input-date class="is-date" v-model="filterDateAfter"></input-date>
                                <span>-</span>
                                <input-date class="is-date" v-model="filterDateBefore"></input-date>
                            </div>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('agency.id')">
                            <async-select
                                api="agencies"
                                v-model="filterAgency"
                                crudLinkTitle="Neue Agentur hinzufügen"
                                placeholder="Agenturen filtern"
                                v-on:load="opt => updateResources({agencies: opt})"
                                multiple
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('hotelType') && options.hotelTypes">
                            <multiselect
                                v-bind:options="options.hotelTypes"
                                v-model="filterHotelType"
                                class="is-shorter"
                                track-by="name"
                                label="name"
                                placeholder="Hotelkategorie filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterHotelType"
                                    v-on:click.prevent.stop="filterHotelType = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('days')">
                            <days
                                v-model="filterDays"
                                picker
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('board') && options.board">
                            <multiselect
                                v-bind:options="options.board"
                                v-model="filterBoard"
                                class="is-shorter"
                                track-by="key"
                                label="value"
                                placeholder="Verpflegung filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterBoard"
                                    v-on:click.prevent.stop="filterBoard = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('roomType') && options.roomType">
                            <multiselect
                                v-bind:options="options.roomType"
                                v-model="filterRoomType"
                                class="is-shorter"
                                track-by="name"
                                label="name"
                                placeholder="Zimmertyp filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterRoomType"
                                    v-on:click.prevent.stop="filterRoomType = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('cabinType') && options.cabinTypes">
                            <multiselect
                                v-bind:options="options.cabinTypes"
                                v-model="filterCabinType"
                                class="is-shorter"
                                track-by="name"
                                label="name"
                                placeholder="Kabinentyp filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCabinType"
                                    v-on:click.prevent.stop="filterCabinType = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('years')">
                            <multiselect
                                v-bind:options="resources.years"
                                v-model="filterYears"
                                class="is-shorter"
                                placeholder="Katalogjahr filtern"
                                multiple
                            >
                                <span slot="caret" ></span>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('tags') && resources.tags">
                            <multiselect
                                v-bind:options="resources.tags"
                                v-model="filterTags"
                                class="is-shorter"
                                track-by="label"
                                label="name"
                                placeholder="Symbole filtern"
                            />
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('traitTags')">
                            <multiselect
                                v-bind:options="options.traitTags ? options.traitTags : []"
                                v-model="filterTraitTags"
                                placeholder="Tags filtern"
                                v-bind:taggable="true"
                                multiple
                                class="is-shorter"
                                v-on:tag="newTag => {
            resources.traitTags = filter['traitTags'] ? filter['traitTags'] : [];
            resources.traitTags.push(newTag);
            update('traitTags', resources.traitTags)
        }"
                            >
                                <span slot="caret" ></span>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('checkedAt') && options._importChecked">
                            <multiselect
                                v-bind:options="options._importChecked"
                                v-model="filterCheckedAt"
                                class="is-shorter"
                                track-by="key"
                                label="value"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCheckedAt"
                                    v-on:click.prevent.stop="filterCheckedAt = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('receivesCatalog')">
                            <multiselect
                                v-bind:options="[{key: true, value: 'Katalog: ja'}, {key: false, value: 'Katalog: nein'}]"
                                v-model="filterReceivesCatalog"
                                class="is-shorter"
                                track-by="key"
                                label="value"
                                placeholder="Katalog"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterReceivesCatalog"
                                    v-on:click.prevent.stop="filterReceivesCatalog = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('hasLinkedIn')">
                            <multiselect
                                v-bind:options="[{key: true, value: 'LinkedIn: ja'}, {key: false, value: 'LinkedIn: nein'}]"
                                v-model="filterHasLinkedIn"
                                class="is-shorter"
                                track-by="key"
                                label="value"
                                placeholder="LinkedIn"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterHasLinkedIn"
                                    v-on:click.prevent.stop="filterHasLinkedIn = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('checkStatus') && options.checkedStatusList">
                            <multiselect
                                v-bind:options="options.checkedStatusList"
                                v-model="filterCheckStatus"
                                track-by="key"
                                class="is-shorter"
                                label="value"
                                placeholder="Status filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCheckStatus"
                                    v-on:click.prevent.stop="filterCheckStatus = null"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('paymentType') && options.paymentType">
                            <multiselect
                                v-bind:options="options.paymentType"
                                v-model="filterPaymentType"
                                track-by="key"
                                class="is-shorter"
                                label="value"
                                multiple
                                placeholder="Zahlungsbedingung"
                            >
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('checkedCatalogText') && options.checkedCatalogText">
                            <multiselect
                                v-bind:options="options.checkedCatalogText"
                                track-by="key"
                                class="is-shorter"
                                label="value"
                                v-model="filterCatalog"
                                placeholder="Katalog filtern"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterCatalog"
                                    v-on:click.prevent.stop="filterCatalog = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('homepageStatus')">
                            <multiselect
                                :allow-empty="true"
                                label="label"
                                track-by="value"
                                :options="[{label: 'online', value: true}, {label: 'offline', value: false}]"
                                v-model="filterHomepageStatus"
                                placeholder="Homepage"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="!!filterHomepageStatus"
                                    v-on:click.prevent.stop="filterHomepageStatus = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <div class="column is-narrow pt-1 pb-1" v-if="isVisible('bookingSituation') && isVisible('bookingSituation[not]')">
                            <multiselect
                                v-bind:options="resources.bookingSituation"
                                track-by="key"
                                class="is-shorter is-medium"
                                label="value"
                                v-model="filterBookingSituation"
                                placeholder="Abgeschlossen?"
                            >
                                <div
                                    slot="clear"
                                    class="multiselect__clear"
                                    v-if="filterBookingSituation"
                                    v-on:click.prevent.stop="filterBookingSituation = ''"
                                ></div>
                            </multiselect>
                        </div>

                        <slot />

                    </div>
                </div>
        </div>



    </div>
</template>



<script>
import debounce from 'lodash/debounce';
import toString from 'lodash/toString';
import includes from 'lodash/includes';
import has from 'lodash/has';

import { Multiselect, AsyncSelect, ToggleSwitch, InputDate, InputMonth } from '@components/form';
import Days from '@components/Days';
import { getDestination, getArea, getPlace, getAgencies, getUsers, getOtherServiceTypes } from '@api';
import {
    buildURLQuery,
    capitalize,
    daysToNumbers,
    getOrganisationsFromStorage,
    numbersToDays
} from '@utilities/functions'
import { countries, tags, tagIcons, years } from '@utilities/variables';

export default {
    components: { Multiselect, AsyncSelect, ToggleSwitch, InputDate, InputMonth, Days },


    props: {
        value: {
            type: Object,
            required: true,
        },

        options: {
            type: Object,
            default: () => {

            }
        },
    },


    mounted: function () {
        if (this.destinationKey && !!this.value[this.destinationKey]) {
            getDestination(this.value[this.destinationKey])
                .then(response => {
                    this.resources.destinations = [response.data]
                })
        }

        if (!!this.value['place.area.id']) {
            getArea(this.value['place.area.id'])
                .then(response => {
                    this.resources.areas = [response.data]
                })
        }

        if (!!this.value['area.id']) {
            getArea(this.value['area.id'])
                .then(response => {
                    this.resources.areas = [response.data]
                })
        }

        if (!!this.value['place.id']) {
            getPlace(this.value['place.id'])
                .then(response => {
                    this.resources.places = [response.data]
                })
        }

        if (!!this.value['agencies.id'] || !!this.value['agency.id']) {
            getAgencies(buildURLQuery(this.value['agencies.id'].map(agencyID => `id[]=${agencyID}`)))
                .then(response => {
                    this.resources.agencies = response.data
                })
        }

        if (!!this.value['otherServices.otherServiceType.id']) {
            getOtherServiceTypes('/' + this.value['otherServices.otherServiceType.id'])
                .then(response => {
                    this.resources.otherServiceTypes = [response.data]
                })
        }

        if (this.isVisible('user.id') || this.isVisible('mainUser.id')  || this.isVisible('mainUsers.id')) {
            getUsers()
                .then(response => {
                    this.resources.users = response.data;
                })
        }
    },


    data: function () {
        return {

            searchValue: this.value && this.value._search ? this.value._search.value : '',

            cityValue: !!this.value.addresses ? this.addresses : '',
            addressesZipValue: !!this.value['addresses.zip'] ? this.value['addresses.zip'] : '',

            resources: {
                destinations: [],
                places: [],
                areas: [],
                agencies: [],
                users: [],
                startPorts: [],
                endPorts: [],
                otherServiceTypes: [],
                traitTags: !!this.value['traitTags'] ? this.value['traitTags'] : [],
                organisations: getOrganisationsFromStorage(),
                countries,
                tags: [...tags, ...tagIcons],
                years: years,
                bookingSituation: [{key: 'notCompleted', value: 'offen'}, {key: 'completed', value: 'abgeschlossen'}],
            }
        }
    },


    computed: {
        filter: function () { return this.value },

        destinationKey: function () {
            if (this.isVisible('place.destination.id')) {
                return 'place.destination.id'
            } else if (this.isVisible('destination.id')) {
                return 'destination.id'
            } else if (this.isVisible('destinations.id')) {
                return 'destinations.id'
            } else if (this.isVisible('trip.destinations.id')) {
                return 'trip.destinations.id'
            } else if (this.isVisible('destinations')) {
                return 'destinations.id'
            }

            return false;
        },

        filterDestination: {
            get: function () {
                return this.resources.destinations.find(destination => destination.id === this.filter[this.destinationKey])
            },
            set: function (destination) { this.update(this.destinationKey, !!destination ? destination.id : '') }
        },

        areaKey: function () {
            return this.isVisible('place.area.id') ? 'place.area.id' : 'area.id';
        },

        userKey: function() {
            if(this.isVisible('mainUsers.id')){
                return 'mainUsers.id';
            }
            return this.isVisible('mainUser.id') ? 'mainUser.id' : 'user.id'
        },

        filterArea: {
            get: function () { return this.resources.areas.find(area => area.id === this.filter[this.areaKey]) },
            set: function (area) { this.update(this.areaKey, !!area ? area.id : '')}
        },

        filterPlace: {
            get: function () { return this.resources.places.find(place => place.id === this.filter['place.id']) },
            set: function (place) { this.update('place.id', !!place ? place.id : '') }
        },

        filterUser: {
            get: function () { return this.resources.users.find(user => user.id === this.filter[this.userKey]) },
            set: function (user) { this.update(this.userKey, !!user ? user.id : '')}
        },

        filterOrganisation: {
            get: function () {
                if (this.resources.organisations) {
                    return this.resources.organisations.find(({id}) => this.filter['organisationFilter.id'] === id)
                } else {
                    return null;
                }
            },
            set: function (value) {
                this.update('organisationFilter.id', value ? value.id : null)
            }
        },

        filterCheckedAt: {
            get: function () { return this.options._importChecked && this.options._importChecked.find(i => i.key === this.filter['checkedAt']) },
            set: function (checked) { this.update('checkedAt', !! checked ? checked.key : null)}
        },

        filterReceivesCatalog: {
            get: function () { return [{key: true, value: 'Katalog: ja'}, {key: false, value: 'Katalog: nein'}].find(i => i.key === this.filter['receivesCatalog']) },
            set: function (checked) { this.update('receivesCatalog', !! checked ? checked.key : null)}
        },

        filterHasLinkedIn: {
            get: function () { return [{key: true, value: 'LinkedIn: ja'}, {key: false, value: 'LinkedIn: nein'}].find(i => i.key === this.filter['hasLinkedIn']) },
            set: function (checked) { this.update('hasLinkedIn', !! checked ? checked.key : null)}
        },

        filterCheckStatus: {
            get: function () { return this.options.checkedStatusList &&
                this.options.checkedStatusList.find(status => status.key === this.filter['checkStatus']) },
            set: function (status) { this.update('checkStatus', !!status ? status.key : null)}
        },

        filterCatalog: {
            get: function () { return this.options.checkedCatalogText &&
                this.options.checkedCatalogText.find(i => i.key == this.filter['checkedCatalogText']) },
            set: function (v) { this.update('checkedCatalogText', !!v ? toString(v.key) : null) }
        },

        filterBookingSituation: {
            get: function () {
                if(this.filter['bookingSituation[not]']) {
                    return this.resources.bookingSituation.filter(item => item.key === 'notCompleted');
                }
                if(this.filter['bookingSituation']) {
                    return this.resources.bookingSituation.filter(item => item.key === 'completed');
                }
                return null;
            },
            set: function (value) {
                if(!value) {
                    this.$emit('input', {...this.value, bookingSituation: null, 'bookingSituation[not]': null})
                } else if(value.key === 'notCompleted') {
                    this.$emit('input', {...this.value, bookingSituation: null, 'bookingSituation[not]': 'completed'})
                } else if(value.key === 'completed') {
                    this.$emit('input', {...this.value, bookingSituation: 'completed', 'bookingSituation[not]': null})
                }
            }
        },

        filterHomepageStatus: {
            get () {
                if(this.filter['exists[webflowLinks.publishedAt]'] === null) {
                    return null;
                }
                return this.filter['exists[webflowLinks.publishedAt]'] ? {label: 'online', value: true} : {label: 'offline', value: false};
            },
            set (v) {
                this.update('exists[webflowLinks.publishedAt]', v ? v.value : null)
            }
        },

        filterAgencies: {
            get: function () { return this.resources.agencies.filter(agency => this.filter['agencies.id'].includes(agency.id)) },
            set: function (agencies) { this.update('agencies.id', agencies.map(agency => agency.id)) }
        },

        filterAgency: {
            get: function () { return this.resources.agencies.filter(agency => this.filter['agency.id'].includes(agency.id)) },
            set: function (agencies) { this.update('agency.id', agencies.map(agency => agency.id)) }
        },

        categoryOptions: function () {
            if (!!this.options.category) {
                return this.options.category
            } else if (!!this.options.categories) {
                return this.options.categories;
            } else {
                return []
            }
        },

        filterOtherServiceTypeCategories: {
            get: function () { return this.options.otherServiceTypeCategories.find(category => category.key === this.filter['otherServiceType.category']) },
            set: function (category) { this.update('otherServiceType.category', !!category ? category.key : '') }
        },

        filterLocale: {
            get () { return this.options.locale.find(item => item.key === this.filter['locale']) },
            set (locale) { this.update('locale', !!locale ? locale.key: '') }
        },

        filterCategory: {
            get: function () { return this.categoryOptions.find(category => category.key === this.filter['category']) },
            set: function (category) { this.update('category', !!category ? category.key : '') }
        },

        filterType: {
            get: function () {
                if (this.isTypeMultiple) {
                    return this.options.types && this.options.types.filter(type => includes(this.filter['type'], type.key))
                } else {
                    return this.options.types && this.options.types.find(type => type.key === this.filter['type'])
                }
            },
            set: function (type){
                if (this.isTypeMultiple) {
                    this.update('type', type.map(({ key }) => key))
                } else {
                    this.update('type', !!type ? type.key : '')
                }
            },
        },

        isTypeMultiple: function() {
            return Array.isArray(this.filter.type)
        },

        filterCountry: {
            get: function () { return this.resources.countries.find(country => country.label === this.filter['country']) },
            set: function (country) { this.update('country', !!country ? country.label : '') }
        },

        filterAddressCountry: {
            get: function () { return this.resources.countries.filter(country => includes(this.filter['addresses.country'], country.label)) },
            set: function (countries) { this.update('addresses.country', countries.map(country => country.label)) }
        },

        filterImportance: {
            get: function () { return this.options.importance && this.options.importance.find(importance => importance.key === this.filter['importance']) },
            set: function (importance) { this.update('importance', !!importance ? importance.key : '') }
        },

        filterOtherServiceTypeCategory: {
            get: function () { return this.options.types && this.options.types.find(type => type.key === this.filter['otherServiceType.category']) },
            set: function (type) { this.update('otherServiceType.category', !!type ? type.key : '') }
        },

        filterOtherServiceType: {
            get: function () { return this.resources.otherServiceTypes.find(service => service.id === this.filter['otherServices.otherServiceType.id']) },
            set: function (service) { this.update('otherServices.otherServiceType.id', service ? service.id : '') }
        },

        filterDifficulty: {
            get: function () { return this.options.difficulties && this.options.difficulties.find(diff => diff.key === this.filter['difficulty']); },
            set: function (diff) { this.update('difficulty', !!diff ? diff.key : '') }
        },

        filterStatuses: {
            get: function () { return this.options.orderStatus &&
                this.options.orderStatus.filter(status => includes(this.filter['currentStatus.status'], status.key)) },
            set: function (statuses) { this.update('currentStatus.status', statuses.map(status => status.key)) }
        },

        filterRequestStatus: {
            get: function () {
                let options = this.options.requestStatuses ? this.options.requestStatuses: this.options.status;
                return options &&
                    options.filter(status => includes(this.filter['requestStatus.status'], status.key))
            },
            set: function (statuses) { this.update('requestStatus.status', statuses.map(status => status.key)) }
        },

        filterClientInvoiceStatuses: {
            get: function () {
                return this.options.type && this.options.type.filter(status => includes(this.filter['status'], status.key))
            },
            set: function (statuses) { this.update('status', statuses.map(status => status.key)) }
        },

        filterDateAfter: {
            get: function () {
                return this.filter['date[after'];
            },
            set: function (date) {
                this.update('date[after]', date)
            }
        },

        filterDateBefore: {
            get: function () {
                return this.filter['date[before'];
            },
            set: function (date) {
                this.update('date[before]', date)
            }
        },

        filterDateText: {
            get: function() {
                return this.filter['dateText'];
            },
            set: function (text) {
                this.$emit('filterDateText', text);
                this.update('dateText', text);
            }
        },

        filterStartAtBefore: {
            get: function () { return this.filter['startAt[before]'] },
            set: function (date) { this.update('startAt[before]', date) },
        },

        filterEndAtAfter: {
            get: function () { return this.filter['endAt[after]'] },
            set: function (date) { this.update('endAt[after]', date) },
        },

        filterTimePeriodsStartAtBefore: {
            get: function () { return this.filter['timePeriods.startAt[before]'] },
            set: function (date) { this.update('timePeriods.startAt[before]', date) },
        },

        filterTimePeriodsEndAtAfter: {
            get: function () { return this.filter['timePeriods.endAt[after]'] },
            set: function (date) { this.update('timePeriods.endAt[after]', date) },
        },

        filterPriceGroupsTimePeriodsStartAtBefore: {
            get: function () { return this.filter['priceGroups.timePeriods.startAt[before]'] },
            set: function (date) { this.update('priceGroups.timePeriods.startAt[before]', date) },
        },

        filterPriceGroupsTimePeriodsEndAtAfter: {
            get: function () { return this.filter['priceGroups.timePeriods.endAt[after]'] },
            set: function (date) { this.update('priceGroups.timePeriods.endAt[after]', date) },
        },

        filterStartAtAfter: {
            get: function () { return this.filter['startAt[after]'] },
            set: function (date) { this.update('startAt[after]', date) },
        },

        filterMonthStartAt: {
            get: function () { return this.filter['startAt[after]'] },
            set: function (date) { this.update('startAt[after]', date)}
        },

        filterMonthEndAt: {
            get: function () { return this.filter['endAt[before]'] },
            set: function (date) { this.update('endAt[before]', date) }
        },

        filterBoard: {
            get: function () { return this.options.board && this.options.board.find(board => board.key === this.filter['board']) },
            set: function (board) { this.update('board', !!board ? board.key : '') }
        },

        filterHotelType: {
            get: function () {
                if (!!this.filter['hotelType'] && this.options.hotelTypes) {
                    const { stars, category } = this.filter['hotelType'];
                    return this.options.hotelTypes.find(type => type.stars === stars && type.category === category)
                }
            },
            set: function (hotelType) {
                this.update('hotelType', !!hotelType ? { stars: hotelType.stars, category: hotelType.category } : null)
            }
        },

        filterRoomType: {
            get: function () {
                if (!!this.filter['roomType'] && this.options.roomType) {
                    const { type, persons } = this.filter['roomType'];
                    return this.options.roomType.find(roomType => roomType.persons === persons && roomType.type === type )
                }
            },
            set: function (roomType) {
                this.update('roomType', !!roomType ? { type: roomType.type, persons: roomType.persons } : null )
            }
        },

        filterCabinType: {
            get: function () {
                if (!!this.filter['cabinType'] && this.options.cabinTypes) {
                    const { type, persons } = this.filter['cabinType'];
                    return this.options.cabinTypes.find(cabinType => cabinType.persons === persons && cabinType.type === type )
                }
            },
            set: function (cabinType) {
                this.update('cabinType', !!cabinType ? { type: cabinType.type, persons: cabinType.persons } : null )
            }
        },

        filterTags: {
            get: function () {
                return this.resources.tags.find(tag => this.filter['tags'] === tag.label)
            },
            set: function (tag) {
                this.update('tags', !!tag ? tag.label : null )
            }
        },

        filterYears: {
            get: function () {
                return this.filter['years']
            },
            set: function (tag) {
                this.update('years', tag )
            }
        },

        filterPaymentType: {
            get: function () { return this.options.paymentType &&
                this.options.paymentType.filter(status => includes(this.filter['paymentType'], status.key)) },
            set: function (paymentType) { this.update('paymentType', paymentType.map(status => status.key)) }
        },

        filterTraitTags: {
            get: function () {
                return this.filter['traitTags'];
            },
            set: function (tags) {
                this.update('traitTags', tags )
            }
        },

        filterDays: {
            get: function () {
                return daysToNumbers(this.filter);
            },

            set: function (numbers) {
                this.update(numbersToDays(numbers));
            }
        },

        filterStartPort: {
            get: function () {
                return this.resources.startPorts.find(port => port.id === this.filter['startPort'])
            },
            set: function (port) { this.update('startPort', !!port ? port.id : '') }
        },

        filterEndPort: {
            get: function () {
                return this.resources.endPorts.find(port => port.id === this.filter['endPort'])
            },
            set: function (port) { this.update('endPort', !!port ? port.id : '') }
        },

        filterAgencyExists: {
            get: function() {
                return this.filter['agency[exists]'];
            },
            set: function(value) {
                this.update('agency[exists]', value)

            }
        }
    },


    methods: {
        updateSearch: debounce(function (e) {
            const value = e.target.value;
            this.update('_search', {...this.filter['_search'], value})
        }, 400),

        updateCity: debounce(function (e) {
            const value = e.target.value;
            this.update('addresses.city', value)
        }, 400),

        updateAddressesZip: debounce(function (e) {
            const value = e.target.value;
            this.update('addresses.zip', value)
        }, 400),

        updateResources: function (obj) {
            this.resources = {...this.resources, ...obj};
        },

        update: function (key, value = null) {
            if (typeof key === 'object') {
                this.$emit('input', {...this.value, ...key})
            } else {
                this.$emit('input', {...this.value, [key]: value})
            }
        },

        isVisible: function (key) {
            return has(this.filter, key)
        },

        userLabel: function (user) {
            return `${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
        }
    }
}
</script>
