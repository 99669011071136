var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "layout",
    [
      !_vm.isLoaded
        ? _c("loading")
        : !_vm.is404
        ? _c(
            "div",
            { staticClass: "crud" },
            [
              _c(
                "aside",
                {
                  staticClass: "main-panel",
                  class: {
                    "is-active": _vm.infos.isActive,
                    "is-visible": _vm.infos.isVisible,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "level" },
                    [
                      _c(
                        "a",
                        { staticClass: "button", attrs: { href: _vm.listURL } },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("keyboard_arrow_left"),
                          ]),
                          _vm._v("zur Übersicht\n                    "),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.has(_vm.item, "mainUsers")
                        ? _c("user-image", {
                            attrs: { user: _vm.item.mainUsers, editable: "" },
                            on: { input: _vm.changeUser },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("item-top"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "level mb" },
                    [
                      _c("h2", { staticClass: "mb-0" }, [
                        _vm._v("HAUPTINFORMATIONEN"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "simple-menu",
                        {
                          attrs: {
                            icon: "more_vert",
                            material: "",
                            buttonWrapper: false,
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openForm.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("edit"),
                              ]),
                              _vm._v(" Bearbeiten"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.deleteItem.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("delete"),
                              ]),
                              _vm._v(" Löschen"),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("item-aside", { staticClass: "mb-2x" }),
                  _vm._v(" "),
                  _vm.hasNotes
                    ? [
                        _c("h2", { staticClass: "mb" }, [
                          _vm._v("Interne Notizen"),
                        ]),
                        _vm._v(" "),
                        _c("tip-tap", {
                          staticClass: "mb",
                          on: { input: _vm.storeNotes },
                          model: {
                            value: _vm.notes,
                            callback: function ($$v) {
                              _vm.notes = $$v
                            },
                            expression: "notes",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "overlay",
                on: { click: _vm.hideOverlay },
              }),
              _vm._v(" "),
              _c("item-tabs", {
                attrs: { tabs: _vm.tabs },
                on: {
                  openForm: _vm.openForm,
                  handleTabChange: _vm.handleTabChange,
                },
              }),
              _vm._v(" "),
              _c("item-form", {
                ref: "form",
                attrs: { formType: "modal", options: _vm.options },
                on: { updated: _vm.handleFormUpdate },
              }),
            ],
            1
          )
        : _c("div", [
            _c("div", { staticClass: "box" }, [
              _c("h1", [_vm._v("Page not found")]),
              _vm._v(" "),
              _c("a", { staticClass: "button", attrs: { href: _vm.listURL } }, [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("keyboard_arrow_left"),
                ]),
                _vm._v("zur Übersicht\n                "),
              ]),
            ]),
          ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }