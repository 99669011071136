import axios from 'axios';
import {ACCESS_DENIED, UNAUTHORIZED} from '@utilities/variables';
import { notifyError } from '@components/Notification';

const base = '/api';


axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status) {
            const { status } = error.response;

            if (status === UNAUTHORIZED && window.location.pathname !== '/login') {
                logout();
            }
            if (status === ACCESS_DENIED && !window.location.pathname.includes('/invoices', '/access_denied')) {
                window.location.replace(`/access_denied`);
            }

            if (!!error.response.data.detail) {
                notifyError(error.response.data.detail)
            }
        }

        return Promise.reject(error);
    }
);


export const login = async function ({ username, password }) {
    const token = await axios.post(base + '/login_check', { username, password });

    localStorage.setItem('user', JSON.stringify(token.data));
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.data.token;

    return axios.post(base + '/users/me').then(user => {
            localStorage.setItem('user', JSON.stringify({
                user: user.data,
                token: token.data.token
            }));
        });
};


export const logout = function () {
    localStorage.removeItem('user');
    window.location.replace(`/login?redirect=${window.location.pathname}`);
};

export const axiosByMethod = function(method, api, data = {}, params = {}, config = {}) {
    return axios({
        method: method,
        url: base + '/' + api,
        data: data,
        params: params,
    });

}


/*
 =======================================================================
 * GET methods
 */

/* Singular */

//Email templates
export const getEmailTemplates = function (params) {
    //
    return axios.get(base+ '/email_templates' , {params})
};
// Locations
export const getArea = function (id) {
    //                   /api/areas/{areaID}
    return axios.get(base + '/areas/' + id);
};

export const getDestination = function (id) {
    //                   /api/destinations/{destinationID}
    return axios.get(base + '/destinations/' + id);
};

export const getPlace = function (id) {
    return axios.get(base + '/places/' + id);
};

// Calculation
export const getCalculation = function (id, params = {}) {
    //                   /api/calculations/{id}
    return axios.get(base + '/calculations/' + id, {
        params
    });
};

export const getCheckBook = function (id) {
    //                   /api/areas/{areaID}
    return axios.get(base + '/check_books/' + id);
};

export const getCalculationPrices = function (id, params = {}) {
    return axios.get(base + '/calculations/' + id + '/prices', {
        params
    });
};

export const getFerryCalculationPrices = function (params = {}) {
    return axios.get(base + '/calculation_price_ferry', {
        params
    });
};

export const getHotelCalculationPrices = function (params = {}) {
    return axios.get(base + '/calculation_price_hotel', {
        params
    });
};

export const getOtherCalculationPrices = function (params = {}) {
    return axios.get(base + '/calculation_price_other', {
        params
    });
};

export const getCalculationContingentPrices = function (params = {}) {
    return axios.get(base + '/calculation_price_contingents', {
        params
    });
};

// Order
export const getOrder = function (id, params = '') {
    return axios.get(base + '/orders/' + id + params);
};

export const getOrderConcepts = function (params = '') {
    return axios.get(base + '/order_concepts' + params);
};

export const getOrderTripDetails = function (orderID, params = {}) {
    return axios.get(base + '/trip_details', {
        params: {
            'order.id': orderID,
            ...params
        }
    })
};

export const getDay = function (id, params = '') {
    return axios.get(base + '/days/' + id + params);
};
export const getDayTripDetails = function (id, params = '') {
    return axios.get(base + '/days/' + id + '/trip_details' + params);
};

// Placeholders
export const getPlaceholder = function ({ type, id, params = '' }) {
    //                   /api/{type}/{placeID}
    return axios.get(base + '/' + type.replace('Placeholders', '') + '_placeholders/' + id + params);
};

export const getFerryPlaceholder = function (id, params = '') {
    //                   /api/ferry_placeholders/{ferryID}
    return axios.get(base + '/ferry_placeholders/' + id + params);
};
export const getTrainPlaceholder = function (id, params = '') {
    //                   /api/ferry_placeholders/{ferryID}
    return axios.get(base + '/train_placeholders/' + id + params);
};

export const getFerryRoute = function (id, params = '') {
    //                   /api/ferry_placeholders/{ferryID}
    return axios.get(base + '/ferry_routes/' + id + params);
};

export const getTrainRoute = function (id, params = '') {
    //                   /api/train_placeholders/{trainID}
    return axios.get(base + '/train_routes/' + id + params);
};

export const getHotelPlaceholder = function (id, params = '') {
    //                   /api/hotel_placeholders/{hotelID}
    return axios.get(base + '/hotel_placeholders/' + id + params);
};

export const getOtherPlaceholder = function (id, params = '') {
    //                   /api/ferry_placeholders/{ferryID}
    return axios.get(base + '/other_placeholders/' + id + params);
};

export const getRequestUpdates = function ({ id, params = '' }) {
    return axios.get(base + '/requests/' + id + '/update' + params);
};

// Requests
export const resetTripDetails = function (id) {
    return axios.get(base + '/requests/' + id + '/reset-trip-details');
};

// Packages
export const getPackage = function (id, params = '') {
    return axios.get(base + '/packages/' + id + params, {
        transformResponse: [function (data) {
            let packageItem = JSON.parse(data);

            packageItem.basePrices.forEach(x => {
                if (x.id !== undefined && x.id === 0) {
                    delete x.id;
                }
            });

            return packageItem;
        }]
    });
};

export const getVoucher = function (id, config = {}) {
    //                   /api/provider_trip_details
    return axios.get(base + '/vouchers/' + id, config);
};

export const getVouchers = function (config = {}) {
    return axios.get(base + '/vouchers'  , config);
};

export const getPackageContingentCalculation = function (packageID) {
    // Used in modal > request > Gesamtkosten
    return axios.get(base + '/packages/' + packageID + '?_groups[]=order_package_calculation_read&_groups[]=order_calculation_read');
};

export const getRequestContingentCalculation = function (requestURL) {
    // Used in modal > request > Gesamtkosten
    return axios.get(base + '/' + requestURL + '?_groups[]=modal_read&_groups[]=order_request_contingent_calculation_read&_groups[]=order_request_contingent_read&_groups[]=order_contingent_item_read&_groups[]=order_request_item_price_read&_groups[]=order_package_read');
};

/* Plural */
export const getAxios = function (api, params = {}, config= {}) {
    return axios.get(base + '/' + api, {...config, params});
};

export const getAddresses = function (params = '') {
    return axios.get(base + '/addresses' + params);
};

export const getAirports = function () {
    //                   /api/airports
    return axios.get(base + '/airports');
};

export const getAirlines = function (params = '', config = {}) {
    return axios.get(base + '/airlines' + params, config);
};

export const getAreas = function (params = '',  config = {}) {
    //                   /api/areas
    return axios.get(base + '/areas' + params, config);
};

export const getAgencies = function (params = '', config = {}) {
    //                   /api/agencies
    return axios.get(base + '/agencies' + params, config);
};

export const getBookingStands = function (params = '') {
    return axios.get(base + '/booking_stands' + params);
};

export const getBankAccounts = function (params = {}) {
    return axios.get(base + '/bank_accounts', {
        params
    });
};

export const getClients = function (params = '') {
    return axios.get(base + '/clients' + params);
};

export const getClientInvoices = function (params = {}) {
    return axios.get(base + '/client_invoices', {
        params
    });
};

export const getContacts = function (params = '') {
    return axios.get(base + '/contacts' + params);
};

export const getChecklistItems = function (params = {}) {
    return axios.get(base + '/checklist_items', {
        params
    })
};

export const getCheckBooks = function (params = {}) {
    return axios.get(base + '/check_books', {
        params
    })
};

export const getDayConcepts = function (params = {}, config = {}, showHiddenConcepts = false) {
    return axios.get(showHiddenConcepts ? base + '/day_concepts/get_all' : base + '/day_concepts', {...config, params});
};

export const getDays = function (params = '', config = {}) {
    return axios.get(base + '/days' + params, config);
};
export const getDaysTripDetails = function (params = '', config = {}) {
    return axios.get(base + '/days/trip_details' + params, config);
};

export const getDestinations = function (params = '',  config = {}) {
    return axios.get(base + '/destinations' + params, config);
};

export const getDocuments = function (params = '', config = {}) {
    return axios.get(base + '/documents' + params, config);
};

export const getOtherServices = function (params = '', config = {}) {
    return axios.get(base + '/other_services' + params, config);
};

export const getOtherService = function (id, params = '') {
    return axios.get(base + '/other_services/' + id + params);
};

export const getInvoices = function (params = '') {
    //                   /api/invoices
    return axios.get(base + '/invoices' + params);
};

export const getInvoice = function (id, params = '') {
    //                   /api/invoices
    return axios.get(base + '/invoices/' + id + params);
};

export const getInvoicePayments = function (params = '') {
    //                   /api/invoice_payments
    return axios.get(base + '/invoice_payments' + params);
};

export const getInfos = function (params = '') {
    //                   /api/infos
    return axios.get(base + '/infos' + params);
};

export const getImages = function (params = {}) {
    //                   /api/images with params obj
    return axios.get(base + '/images', {
        params
    });
};

export const getHotels = function (params = '') {
    return axios.get(base + '/hotels' + params);
};

export const getFaqs = function (params = {}) {
    return axios.get(base + '/faqs', {
        params
    })
};

export const getFerries = function (params = '') {
    return axios.get(base + '/ferries' + params);
};

export const getFerryRoutes = function (params = {}) {
    return axios.get(base + '/ferry_routes', {
        params
    })
};

export const getTrains = function (params = '') {
    return axios.get(base + '/trains' + params);
};

export const getTrainRoutes = function (params = {}) {
    return axios.get(base + '/train_routes', {
        params
    })
};

export const getFerryPlaceholders = function (param = '') {
    return axios.get(base + '/ferry_placeholders' + param);
};

export const getFreePlaces = function (params = {}) {
    return axios.get(base + '/provider_free_places', {
        params
    });
};

export const getFerryPrices = function (params = {}) {
    return axios.get(base + '/ferry_prices', {
        params
    });
};

export const getOffers = function (params = '') {
    return axios.get(base + '/offers' + params);
};

export const getOfferIntroTemplates = function () {
    return axios.get(base + '/offer_intro_templates');
};

export const getOrders = function (stringParams = '', params = {}, cancelToken = null) {
    return axios.get(base + '/orders' + stringParams, {
        params,
        cancelToken
    });
};

export const getOthers = function () {
    return axios.get(base + '/others');
};

export const getOtherPrices = function (params = {}, groups ='') {
    return axios.get(base + '/other_service_prices' + groups, {
        params
    });
};

export const getOtherServiceTypes = function (params = '',  config = {}) {
    return axios.get(base + '/other_service_types' + params, config);
};

export const getPackages = function (params = '') {
    //                   /api/packages
    return axios.get(base + '/packages' + params);
};

export const getPlaces = function (params = '',  config = {}) {
    //                   /api/places
    return axios.get(base + '/places' + params, config);
};

export const getPorts = function (params = '') {
    //                   /api/ports
    return axios.get(base + '/ports' + params);
};

export const getPredefinedRoutesList = function (params = '', config = {}) {
    //                   /api/predefined_route_details
    return axios.get(base + '/predefined_route_details' + params, config);
};

export const getProviders = function (params = '') {
    return axios.get(base + '/providers' + params);
};

export const getRequests = function (params = '') {
    return axios.get(base + '/requests' + params);
};



export const getRequestsByOrder = function (orderId, params = '', specialParams = null, providerId = null) {
    let linksArr = [];

    if (specialParams) {
        switch (specialParams.type) {
            case 'hotel':
                linksArr = [base + '/hotel_requests?placeholder.day.order='   + orderId + params + '&hotel.id='   + specialParams.id];
                break;
            case 'ferry':
                linksArr = [base + '/ferry_requests?placeholder.day.order='   + orderId + params + '&ferry.id='   + specialParams.id];
                break;
            case 'train':
                linksArr = [base + '/train_requests?placeholder.day.order='   + orderId + params + '&train.id='   + specialParams.id];
                break;
            case 'airline':
                linksArr = [base + '/airline_requests?placeholder.day.order=' + orderId + params + '&airline.id=' + specialParams.id];
                break;
            case 'other':
                linksArr = [base + '/other_requests?placeholder.day.order='   + orderId + params + '&otherService.provider.id=' + specialParams.id];
                break;
        }

    } else {
        linksArr = [
            base + '/hotel_requests?placeholder.day.order='   + orderId + params + (providerId ? '&hotel.id='   + providerId : ''),
            base + '/ferry_requests?placeholder.day.order='   + orderId + params + (providerId ? '&ferry.id='   + providerId : ''),
            base + '/train_requests?placeholder.day.order='   + orderId + params + (providerId ? '&train.id='   + providerId : ''),
            base + '/airline_requests?placeholder.day.order=' + orderId + params + (providerId ? '&airline.id='   + providerId : ''),
            base + '/other_requests?placeholder.day.order='   + orderId + params + (providerId ? '&otherService.provider.id='   + providerId : '')
        ];
    }

    return Promise.all(linksArr.map(apiSintrg => axios.get(apiSintrg)));
};

export const getTodoList = function (params = '') {
    //                   /api/todos
    return axios.get(base + '/todos' + params);
};

export const getTodos = function (params = {}) {
    return axios.get(base + '/todos', {
        params
    })
};

export const getTripDetails = function (params = '') {
    //                   /api/trip_details
    return axios.get(base + '/trip_details' + params);
};

export const getProviderTripDetails = function (params = {}) {
    //                   /api/provider_trip_details
    return axios.get(base + '/provider_trip_details', {
        params
    });
};

export const getTripDetailTemplates = function (params = {}) {
    return axios.get(base + '/trip_detail_templates', {
        params
    });
};

export const getRenderedTripDetailTemplates = function (params = {}) {
    return axios.get(base + '/trip_detail_templates/render/get', {
        params
    });
};

export const getBulletPointTemplates = function (params = {}) {
    //                   /api/provider_trip_details
    return axios.get(base + '/bullet_point_templates', {
        params
    });
};

export const getVoucherTemplates = function (params = {}) {
    //                   /api/provider_trip_details
    return axios.get(base + '/voucher_templates', {
        params
    });
};

export const getWebflowHomepages = function (params = {}) {
    return axios.get(base + '/webflow_homepages', {
        params
    });
};

export const getUsers = function (params = '') {
    // filter out trav_admin
    return new Promise((resolve, reject) => {
        //            /api/users
        axios.get(base + '/users' + params)
        .then(response => {
            const newResponse = Object.assign({}, response, {
                data: response.data.filter(user => user.username !== 'trav_admin')
            });

            resolve(newResponse)
        }, error => reject(error))
    })
};

export const getHotelPrices = function (params = {}) {
    return axios.get(base + '/hotel_prices', {
        params
    });
};

export const getHotelPriceGroups = function (params = {}) {
    return axios.get(base + '/hotel_price_groups', {
        params
    });
};

export const getFerryPriceGroups = function (params = {}) {
    return axios.get(base + '/ferry_price_groups', {
        params
    });
};

export const getOtherPriceGroups = function (params = {}) {
    return axios.get(base + '/other_price_groups', {
        params
    });
};

export const getTrainPriceGroups = function (params = {}) {
    return axios.get(base + '/other_price_groups', {
        params
    });
};

export const getGroupedHotelProviderPrices = function (params = {}) {
    return axios.get(base + '/hotel_price_groups/grouped', {
        params
    });
};

export const getGroupedFerryRoutes = function (params = {}) {
    return axios.get(base + '/ferry_routes/grouped', {
        params
    });
};

export const getGroupedTrainRoutes = function (params = {}) {
    return axios.get(base + '/train_routes/grouped', {
        params
    });
};


export const getGroupedOtherServices = function (params = {}) {
    return axios.get(base + '/other_services/grouped', {
        params
    });
};

export const getProviderItemPrices = function (params = {}) {
    return axios.get(base + '/provider_item_prices', {
        params
    });
};

export const getConceptSellingPrices = function (params = {}) {
    return axios.get(base + '/selling_prices', {
        params
    });
};

// Preset prices (calculation prices)
export const getHotelPresetPrice = function (id, params = '') {
    //                   /api/hotel_preset_prices/{presetID}
    return axios.get(base + '/hotel_preset_prices/' + id + params);
};

export const getRequest = function (id, params = {}) {
    return axios.get(base + '/requests/' + id, {params});
};

export const getRequestItemPrice = function (id, params = {}) {
    return axios.get(base + '/request_item_prices/' + id, {params});
};

export const getHotelRequestContingentRoom = function (id, params = {}) {
    return axios.get(base + '/hotel_request_contingent_rooms/' + id, {params});
};

export const getOtherRequestContingent = function (id, params = {}) {
    return axios.get(base + '/other_request_contingents/' + id, {params});
};

export const getHotelRequestContingent = function (id, params = {}) {
    return axios.get(base + '/order_request_contingent_hotel/' + id, {params});
};


export const getHotelPresetPrices = function (params = {}) {
    //                   /api/hotel_preset_prices
    return axios.get(base + '/hotel_preset_prices', {
        params: Object.assign({}, {
            '_groups': ['destination_read', 'area_read', 'order_contingent_item_read']
        }, params)
    });
};

export const getFerryPresetPrice = function (id, params = '') {
    //                   /api/ferry_preset_prices/{presetID}
    return axios.get(base + '/ferry_preset_prices/' + id + params);
};

export const getFerryPresetPrices = function (params = {}) {
    //                   /api/ferry_preset_prices
    return axios.get(base + '/ferry_preset_prices', {
        params: Object.assign({}, {
            '_groups': ['port_read', 'place_read', 'order_contingent_item_read']
        }, params)
    });
};

export const getOtherPresetPrice = function (id, params = '') {
    //                   /api/other_preset_prices/{presetID}
    return axios.get(base + '/other_preset_prices/' + id + params);
};

export const getOtherPresetPrices = function (params = {}) {
    //                   /api/other_preset_prices
    return axios.get(base + '/other_preset_prices', {
        params: Object.assign({}, {
            '_groups': ['destination_read', 'area_read', 'other_service_type_read']
        }, params)
    });
};

export const getLocales = function (params = {}) {
    return axios.get(base + '/locales', {
        params
    });
};

export const getLiquidityItems = function (params = {}) {
    return axios.get(base + '/liquidity_items', {
        params
    });
};

export const getPredefinedFreeTexts = function (params = '', config = {}) {
    return axios.get(base + '/predefined_free_text_items' + params, config);
};

export const getFreeTextItems = function (params = '') {
    return axios.get(base + '/free_text_items' + params);
};

export const getOrganisations = function (params = {}) {
    return axios.get(base + '/organisations', {
        params
    });
};

export const getOrganisationBankAccounts = function (params = {}) {
    return axios.get(base + '/organisation_bank_accounts', {
        params
    });
};

export const getBanks = function (params = {}) {
    return axios.get(base + '/banks', {
        params
    });
};

/*
 * ================================== Filters providers ==========================
 */
export const getFilteredFerries = function ({ startPort, endPort, routeType, config = {} }) {
    return axios.get(base + '/ferries', {params:
            {
                ...startPort && {'ferryRoutes.startPort.id': startPort.id},
                ...endPort && {'ferryRoutes.endPort.id': endPort.id},
                ...routeType && {'ferryRoutes.type': routeType},
                '_groups': ['agency_read_list'],
                ...config
        } });
};

/*
 * ================================== end of Filters providers ==========================
 */

export const exportApi = function(api, params = {}, locale = null) {
    return axios.get(`${base}/${api}/export`, {
        params,
        ...locale && {headers: {'X-LOCALE': locale}}
    })
};

export const exportRelatedText = function(id, params = {}) {
    return axios.get(base + '/day_concepts/export_related_text/' + id, {
        params
    });
};

export const exportEmail = function(id) {
    return axios.get(base + '/emails/save/' + id)
};

/*
 =======================================================================
 * POST methods
 *
 *      /endpoint       with the data {name:'John', age: 50, ...}
 */

export const post = function (api, data, params = {}) {
    return axios.post(base + '/' + api, data, {params});
};

export const editTripDetailSequence = function (data) {
    return axios.post(base + '/trip_details/update_positions', {
        sequence: data
    });
};

export const editTripDetailTemplatesSequence = function (data) {
    return axios.post(base + '/trip_detail_templates/update_positions', {
        sequence: data
    });
};

export const editBulletPointTemplatesSequence = function (data) {
    return axios.post(base + '/bullet_point_templates/update_positions', {
        sequence: data
    });
};

export const editProviderTripDetailSequence = function (data) {
    return axios.post(base + '/providers/update_positions', {
        sequence: data
    });
};

export const addAirport = function (data, params = {}) {
    return axios.post(base + '/airports', data, params);
};

export const addOrganisation = function (data) {
    return axios.post(base + '/organisations', data);
};

export const addAirline = function (data, params = {}) {
    //                                              /api/airlines with the data
    return axios.request({ method: 'post', url: `${base}/airlines`, data,
        params
    })
};


export const addAirlinePlaceholder = function (data) {
    return axios.post(base + '/airline_placeholders', data);
};

export const addAirlineRequest = function (data) {
    return axios.post(base + '/airline_requests', data);
};

export const addAgency = function (data, params = {}) {
    //                                              /api/agencies with the data
    return axios.request({ method: 'post', url: `${base}/agencies`, data,
        params
    })
};

// Adds a contact and links it to the agency with the id
export const addAgencyContact = function ({ agencyID, data }) {
    //                    /api/contacts with the data {name:'John', age: 50, ...}
    return axios.post(base + '/agencies/' + agencyID + '/contacts', data);
};

export const addArea = function (data, params = {}) {
    return axios.post(base + '/areas', data, params);
};

export const addBookingPlan = function (params = {}) {
    return axios.post(base + '/documents/booking-plan/generate-document', {}, {
        params
    });
};

export const convertDocument = function (documentId, params = {}) {
    return axios.post(base + '/documents/convertToPdf?documentId=' + documentId, {}, {
        params
    })
}

export const getMicrosoftPreviewData = function (id) {
    return axios.options(base + '/documents/' + id + '/cloud-preview-path')
}

export const addBankAccount = function (data = {}, params = {}) {
    return axios.post(base + '/bank_accounts', data, {params});
};

export const addCalculation = function (data) {
    return axios.post(base + '/calculations', data);
};

export const addChecklistItem = function(data) {
    return axios.post(base + '/checklist_items', data, {
        params: {
            _groups: 'order_checklist_item:order'
        }
    });
};

export const addCalculationPriceExtra = function (data, params = '') {
    return axios.post(base + '/calculation_price_extras' + params, data);
};

export const addClient = function (data, params = "") {
    //                    /api/clients with the data {name:'John', age: 50, ...}
    return axios.post(base + '/clients' + params, data);
};
export const addBank = function (data, params = {}) {
    //                    /api/clients with the data {name:'John', age: 50, ...}
    return axios.post(base + '/banks', data, {params});
};

export const addAccountPlanItem = function (data, params = {}) {
    //                    /api/clients with the data {name:'John', age: 50, ...}
    return axios.post(base + '/account_plan_items', data, {params});
};

export const addClientInvoice = function (data) {
    return axios.post(base + '/client_invoices', data);
};

export const addDay = function (data, params = {}) {
    return axios.post(base + '/days', data, {params});
};

export const addDayConcept = function (data, params = {}) {
    //                                              /api/day_concepts with the data
    return axios.request({ method: 'post', url: `${base}/day_concepts`, data,
        params
    })
};

export const addDayConceptFromDay = function ({ id, data, params }) {
    return axios.post(base + '/day_concepts/create_from_day/' + id, data, {params});
};

export const addDayFromConcept = function ({ id, data, params = {} }) {
    return axios.post(base + '/days/create_from_concept/' + id, data, {
        params
    });
};

export const addDestination = function (data) {
    return axios.post(base + '/destinations', data);
};

export const addDocument = function (data) {
    return axios.post(base + '/documents/upload', data);
};

export const addDocumentExcel = function(data) {
    return axios.post(base + '/documents/generate-excel', data);
};

export const addEmail = function (data, toFolder = '') {
    let toFolderAPI = toFolder ? '?toFolder=' + toFolder : '';

    return axios.post(base + '/emails' + toFolderAPI, data);
};

export const addFaq = function (data, params = {}) {
    //                                              /api/faqs with the data
    return axios.request({ method: 'post', url: `${base}/faqs`, data,
        params
    })
};

export const addFerry = function (data, params = {}) {
    //                                              /api/ferries with the data
    return axios.request({ method: 'post', url: `${base}/ferries`, data,
        params
    })
};

export const addTrain = function (data, params = {}) {
    return axios.request({ method: 'post', url: `${base}/trains`, data,
        params
    })
};

export const addFerryRoute = function (data, params = {}) {
    return axios.post(base + '/ferry_routes', data, {
        params
    });
};

export const addTrainRoute = function (data, params = {}) {
    return axios.post(base + '/train_routes', data, {
        params
    });
};

export const addFreePlace = function (data, params = {}) {
    //                                              /api/provider_free_places
    return axios.request({ method: 'post', url: `${base}/provider_free_places`, data,
        params
    })
};

export const addFerryPlaceholder = function (data) {
    return axios.post(base + '/ferry_placeholders', data);
};

export const addFerryRequest = function (data) {
    return axios.post(base + '/ferry_requests', data);
};

export const addHotel = function (data, params = '?_groups[]=provider_property&_groups[]=data_check_write') {
    return axios.post(base + '/hotels' + params, data);
};

export const addHotelPlaceholder = function (data) {
    return axios.post(base + '/hotel_placeholders', data);
};

export const addHotelRequest = function (data) {
    return axios.post(base + '/hotel_requests', data);
};

export const addHotelPrice = function (data, params = '') {
    return axios.post(base + '/hotel_prices' + params, data);
};

export const addInfo = function (data) {
    return axios.post(base + '/infos', data);
};

export const addImage = function (data) {
    return axios.post(base + '/images/upload', data);
};

export const addGoogleImage = function (data) {
    return axios.post(base + '/images', data);
};

export const addOffer = function (params) {
    return axios.post(base + '/offers', params);
};

export const addOfferIntroTemplate = function (data) {
    return axios.post(base + '/offer_intro_templates', data);
};

export const addTripDetailTemplate = function (data) {
    return axios.post(base + '/trip_detail_templates', data);
};

export const addBulletPointTemplate = function (data) {
    return axios.post(base + '/bullet_point_templates', data);
};

export const addVoucherTemplate = function (data, params = {}) {
    return axios.post(base + '/voucher_templates', data, params);
};

export const addOrder = function (data, params = '') {
    //                    /api/orders with the data {client: {...}, contact, {...}, ...}
    return axios.post(base + '/orders' + params, data);
};

export const addOrderFromOrder = function ({id, data, params = ''}) {
    return axios.post(base + '/orders/create_from_order/' + id + params, data);
};

export const addOrderConcept = function (data, params = {}) {
    //                                              /api/order_concepts with the data
    return axios.request({ method: 'post', url: `${base}/order_concepts`, data,
        params
    })
};

export const addOrderFromConcept = function ({ id, data, params = {} }) {
    //                    /api/create_from_concept with the data {client: {...}, contact, {...}, ...}
    return axios.post(base + '/orders/create_from_concept/' + id, data, {
        params
    });
};

export const addOrderContact = function (data,  params = {}) {
    return axios.post(base + '/order_contacts', data, {params})
};

export const addOther = function (data, params = {}) {
    //                                              /api/others with the data
    return axios.request({ method: 'post', url: `${base}/others`, data,
        params
    })
};

export const addOtherService = function (data, params = {}) {
    return axios.post(base + '/other_services', data, {params});
};

export const addOtherServiceToProviders = function (id, data = {}, params = {}) {
    return axios.post(base + '/other_service_types/' + id + '/add_to_all_providers', data, {
        params
    });
};

export const addOtherServiceType = function (data, params = {}) {
    //                                              /api/other_service_types with the data
    return axios.request({ method: 'post', url: `${base}/other_service_types`, data,
        params
    })
};
export const addMenu= function (data, params = {}) {
    //                                              /api/other_service_types with the data
    return axios.request({ method: 'post', url: `${base}/menus`, data,
        params
    })
};


export const addOtherRequest = function (data) {
    return axios.post(base + '/other_requests', data);
};

export const addOtherPrice = function (data, params = '') {
    return axios.post(base + '/other_service_prices' + params, data);
};

export const addPackage = function (data, params) {
    return axios.post(base + '/packages' + params, data);
};

export const addPlace = function (data, params = {}) {
    return axios.post(base + '/places', data, params);
};

export const addLocationCharge = function (data) {
    return axios.post(base + '/location_charges', data);
};

export const addPlaceholder = function ({type, data, params = '' }) {
    return axios.post(base + '/' + type + '_placeholders' + params, data);
};

export const addPort = function (data, params = {}) {
    return axios.post(base + '/ports', data, params);
};

export const addTrainStation = function (data) {
    return axios.post(base + '/train_stations', data);
};

export const addPredefinedRoute = function (data, params = {}) {
    return axios.request({ method: 'post', url: `${base}/predefined_route_details`, data,
        params
    })
};

export const addPredefinedFreeText = function (data, params = {}) {
    return axios.request({ method: 'post', url: `${base}/predefined_free_text_items`, data,
        params
    })
};

export const addFreeTextItem = function (data, params = {}) {
    return axios.request({ method: 'post', url: `${base}/free_text_items`, data,
        params
    })
};

export const addOtherPlaceholder = function (data) {
    return axios.post(base + '/other_placeholders', data);
};

export const addRequest = function ({ apiEndpoint, data }) {
    return axios.post(base + '/' + apiEndpoint, data);
};

export const addRequestItemPrice = function (data, params = {}) {
    return axios.post(base + '/request_item_prices', data, {params});
};

export const addRouteDetail = function (data) {
    return axios.post(base + '/route_details', data);
};

export const addRoom = function (data) {
    return axios.post(base + '/rooms', data);
};

export const addRoomList = function (data, params = {}) {
    return axios.post(base + '/room_lists', data, {params});
};

export const addTodo = function (data, params = "") {
    return axios.post(base + '/todos' + params, data);
};

export const addTripDetail = function (data, params = {}, config = {}) {
    return axios.post(base + '/trip_details', data, {...config, params});
};

export const addSellingPrice = function (data, params = {}) {
    return axios.post(base + '/selling_prices', data, {
        params
    });
};

export const generateTripDetails = function (orderID, params = {}) {
    return axios.post(base + '/orders/' + orderID + '/trip-details/generate-document', {}, {
        params
    })
};

export const addInvoice = function (data, params = {}) {
    return axios.post(base + '/invoices', data, {
        params
    });
};

export const addUser = function (data) {
    return axios.post(base + '/users', data);
};

export const addVoucher = function (data) {
    return axios.post(base + '/vouchers', data);
};

// Preset prices
export const addHotelPresetPrice = function (data) {
    return axios.post(base + '/hotel_preset_prices?_groups[]=destination_read&_groups[]=area_read&_groups[]=order_contingent_item_read&_groups[]=order_contingent_item_write', data);
};

export const addFerryPresetPrice = function (data) {
    return axios.post(base + '/ferry_preset_prices?_groups[]=port_read&_groups[]=place_read&_groups[]=order_contingent_item_read&_groups[]=order_contingent_item_write', data);
};

export const addOtherPresetPrice = function (data) {
    return axios.post(base + '/other_preset_prices?_groups[]=other_service_type_read', data);
};

export const addEmailAssign = function (data, params = '') {
    return axios.post(base + '/emails/dnd_assign' + params, data);
};

export const addClientInvoicePayment = function (data, params = {}) {
    //                    /api/client_invoices/23 with the data {name:'John', age: 50, ...}
    return axios.post(base + '/client_invoice_payments', data, {params});
};

/*
 =======================================================================
 * PUT methods
 */

//           editAirport({ id: 23, data: data });
export const editAirport = function ({ id, data }, params = {}, config = {}) {
    //                    /api/airports/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/airports/' + id, data, {
        ...config,
        params
    });
};
//           editOrganisation({ id: 23, data: data });
export const editOrganisation = function ({ id, data }) {
    //                    /api/airports/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/organisations/' + id, data);
};

//           editAirline({ id: 23, data: data });
export const editAirline = function ({ id, data, params = '', config = {}  }) {
    //                    /api/airlines/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/airlines/' + id + params, data, config);
};

//           editAirlinePlaceholder({ id: 23, data: data });
export const editAirlinePlaceholder = function ({ id, data }, params = {}) {
    //                    /api/airline_placeholders/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/airline_placeholders/' + id, data, {
        params
    });
};

export const editAirlineRequest = function ({ id, data }) {
    return axios.put(base + '/airline_requests/' + id, data);
};

export const editAgency = function ({ id, data, params = '' }) {
    //                    /api/agencies/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/agencies/' + id + params, data);
};

//           editArea({ id: 23, data: data });
export const editArea = function ({ id, data }, params = {}, config = {}) {
    //                    /api/areas/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/areas/' + id, data, {
        ...config,
        params
    });
};

export const editCalculation = function ({ id, data, params = {}, saveAndGet = false }) {
    //                    api/calculations/23 with data {order: '', ...}
    if(saveAndGet){
        return axios.put(base + '/calculations/' + id + '/prices', data, {params});
    }
    return axios.put(base + '/calculations/' + id, data, {params});
};

export const editChecklistItem = function({id, data}) {
    return axios.put(base + '/checklist_items/' + id, data);
};

export const editCalculationPrices = function (type, {id, data}) {
    return axios.put(base + '/calculation_price_' + type + '/' + id, data);
};

export const editCalculationContingentPrice = function ({id, data}) {
    return axios.put(base + '/calculation_price_contingents/' + id, data)
};

export const editCalculationPriceExtra = function ({ id, data, params = '' }) {
    return axios.put(base + '/calculation_price_extras/' + id + params, data);
};

export const editClient = function ({ id, data, params = '' }) {
    //                    /api/clients/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/clients/' + id + params, data);
};

export const editBank = function ({ id, data, params = '' }) {
    //                    /api/clients/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/banks/' + id + params, data);
};

export const editClientInvoice = function ({ id, data }) {
    //                    /api/client_invoices/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/client_invoices/' + id, data);
};

export const editClientInvoicePayment = function ({id, data, params = {}}) {
    //                    /api/client_invoices/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/client_invoice_payments/' + id, data, {params});
};

//           editDayConcept({ id: 23, data: data });
export const editDayConcept = function ({ id, data, params = '' }) {
    //                   /api/day_concepts/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/day_concepts/' + id + params, data);
};

//           editDay({ id: 23, data: data });
export const editDay = function ({ id, data }) {
    //                   /api/days/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/days/' + id, data);
};

//           editDestination ({ id: 23, data: data });
export const editDestination = function ({ id, data }) {
    //                   /api/destinations/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/destinations/' + id, data);
};

export const editDocument = function ({ id, data }) {
    //                   /api/documents/32 with the data
    return axios.put(base + '/documents/' + id, data);
};

export const editEmail = function ({ id, data, params = '' }) {
    //                   /api/emails/32 with the data
    return axios.put(base + '/emails/' + id + params, data);
};

export const editImage = function ({ id, data }) {
    //                   /api/images/23 with the data {title:'Landscape', type: 'photo", ...}
    return axios.put(base + '/images/' + id, data);
};

//           editFaq({ id: 23, data: data });
export const editFaq = function ({ id, data }) {
    //                    /api/faq/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/faqs/' + id, data);
};

//           editFerry({ id: 23, data: data });
export const editFerry = function ({ id, data, params = '' }) {
    //                    /api/ferries/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/ferries/' + id + params, data);
};

export const editTrain = function ({ id, data, params = '' }) {
    return axios.put(base + '/trains/' + id + params, data);
};

export const editFerryRoute = function ({ id, data }, params = {}) {
    return axios.put(base + '/ferry_routes/' + id , data, {
        params
    });
};

export const editTrainRoute = function ({ id, data }, params = {}) {
    return axios.put(base + '/train_routes/' + id , data, {
        params
    });
};

export const editFreePlace = function ({ id, data, params = '' }) {
    //                   /api/provider_free_places/23 with data
    return axios.put(base + '/provider_free_places/' + id + params, data);
};

//           editFerryContingent(data);
export const editFerryContingent = function ({ id, data }) {
    //                    /api/order_contingent_ferries with the data {name:'John', age: 50, ...}
    return axios.put(base + '/order_contingent_ferries/' + id, data);
};

//           editFerryPlaceholder({ id: 23, data: data });
export const editFerryPlaceholder = function ({ id, data }) {
    //                    /api/ferry_placeholders/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/ferry_placeholders/' + id, data);
};

export const editFerryRequest = function ({ id, data }) {
    return axios.put(base + '/ferry_requests/' + id, data);
};

//           editHotel({ id: 23, data: data });
export const editHotel = function ({ id, data, params = '?_groups[]=provider_property&_groups[]=data_check_write' }) {
    //                    /api/hotels/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/hotels/' + id + params, data);
};

//           editHotelContingent(data);
export const editHotelContingent = function ({ id, data }) {
    //                    /api/order_contingent_hotels with the data {name:'John', age: 50, ...}
    return axios.put(base + '/order_contingent_hotels/' + id, data);
};

//           editHotelPlaceholder({ id: 23, data: data });
export const editHotelPlaceholder = function ({ id, data }) {
    //                    /api/hotel_placeholders/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/hotel_placeholders/' + id, data);
};

export const editHotelRequest = function ({ id, data }) {
    return axios.put(base + '/hotel_requests/' + id, data);
};

export const editHotelRequestContingentRoom = function ({ id, data, params = {} }) {
    return axios.put(base + '/hotel_request_contingent_rooms/' + id + '/update_free_place', data, {params});
};

export const editOtherRequestContingent = function ({ id, data, params = {} }) {
    return axios.put(base + '/other_request_contingents/' + id + '/update_free_place', data, {params});
};

export const editRequestItemPrice = function ({ id, data, params = {} }) {
    return axios.put(base + '/request_item_prices/' + id, data, {params});
};

export const editHotelPrice = function ({id, data, params = ''}) {
    return axios.put(base + '/hotel_prices/' + id + params, data);
};

export const editOffer = function ({ id, data}) {
    return axios.put(base + '/offers/' + id, data);
};

export const editOfferIntroTemplate = function ({id, data}) {
    return axios.put(base + '/offer_intro_templates/' + id, data);
};

export const editTripDetailTemplate = function ({id, data}) {
    return axios.put(base + '/trip_detail_templates/' + id, data);
};

export const editBulletPointTemplate = function ({id, data}) {
    return axios.put(base + '/bullet_point_templates/' + id, data);
};

export const editVoucherTemplate = function ({id, data, config = {}}) {
    return axios.put(base + '/voucher_templates/' + id, data, config);
};

export const editOrder = function ({ id, data, params = '' }) {
    return axios.put(base + '/orders/' + id + params, data);
};

export const changeAllDates = function ({ id, data, params= {} }) {
    return axios.put(base + '/orders/' + id+'/change_all_dates', data, {params});
};

export const cancelAllRequests = function ({ id, data, params= {} }) {
    return axios.put(base + '/orders/' + id + '/change_all_requests', data, {params});
};

export const editOrderConcept = function ({ id, data, params = '' }) {
    return axios.put(base + '/order_concepts/' + id + params, data);
};

export const publishOrderConcept = function ({ id, year, data,  params = {} }) {
    return axios.put(base + '/concepts/orders/' + id + '/publish/' + year, data, {params});
};

export const editOrderStatus = function ({id, status}) {
    return axios.post(base + '/orders/' + id + '/currentStatus', {
        status
    });
};

export const editOrderContact = function ({ id, data, params }) {
    return axios.put(base + '/order_contacts/' + id, data, {params});
};

//           editOtherPlaceholder({ id: 23, data: data });
export const editOtherPlaceholder = function ({ id, data }) {
    //                    /api/other_placeholders/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/other_placeholders/' + id, data);
};

export const editOther = function ({ id, data, params = ''  }) {
    return axios.put(base + '/others/' + id + params, data);
};

export const editOtherService = function ({ id, data, params = '' }) {
    return axios.put(base + '/other_services/' + id + params, data);
};

export const editOtherServiceType = function ({ id, data, params = '', config = {} }) {
    return axios.put(base + '/other_service_types/' + id + params, data, config);
};

export const editOtherRequest = function ({ id, data }) {
    return axios.put(base + '/other_requests/' + id, data);
};

export const editOtherPrice = function ({id, data, params = ''}) {
    return axios.put(base + '/other_service_prices/' + id + params, data);
};

//           editPackage({ id: 23, data: data });
export const editPackage = function ({ id, data, params = '' }) {
    //                    /api/packages/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/packages/' + id + params, data);
};

//           editPort({ id: 23, data: data });
export const editPort = function ({ id, data }) {
    //                    /api/ports/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/ports/' + id, data);
};

//           editTrainStation({ id: 23, data: data });
export const editTrainStation = function ({ id, data }, params = {}, config = {}) {
    //                    /api/train_stations/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/train_stations/' + id, data, {
        ...config,
        params
    });
};

//           editPlace({ id: 23, data: data });
export const editPlace = function ({ id, data }, params = {}, config = {}) {
    //                    /api/places/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/places/' + id, data, {
        ...config,
        params
    });
};

//           editLocationCharge({ id: 23, data: data });
export const editLocationCharge = function ({ id, data }) {
    //                    /api/location_charges/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/location_charges/' + id, data);
};

export const editPlaceholder = function ({ type, id, data, params = '' }) {
    return axios.put(base + '/' + type + '/' + id + params, data);
};

export const editRequest = function ({ apiEndpoint, id, data, params = '' , cancelToken}) {
    //                     /api/other_request/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/' + apiEndpoint + '/' + id + params, data, {cancelToken});
};



export const updateRequestProgress = function (id, tripDetailProgress = 0) {
    return axios.put(base + '/requests/' + id + '/progress', {
        tripDetailProgress
    });
};

export const updateDayProgress = function (id, tripDetailProgress = 0) {
    return axios.put(base + '/days/' + id, {
        tripDetailProgress
    });
};

//           editRouteDetail({ id: 23, data: data });
export const editRouteDetail = function ({ id, data}) {
    //                    /api/route_details/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/route_details/' + id, data);
};

export const editRoom = function ({id, data}) {
    return axios.put(base + '/rooms/' + id, data);
};

export const editRoomList = function ({id, data}) {
    return axios.put(base + '/room_lists/' + id, data);
};

export const editSellingPrice = function ({id, data}, params = {}) {
    return axios.put(base + '/selling_prices/'+ id, data, {
        params
    });
};

export const editPrediefinedRouteDetails = function ({ id, data, params = "", config = {} }) {
    //                    /api/predefined_route_details/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/predefined_route_details/' + id + params, data, config);
};

export const editPredefinedFreeText = function ({ id, data, params = "", config = {}}) {
    return axios.put(base + '/predefined_free_text_items/' + id + params, data, config);
};

export const editFreeTextItem = function ({ id, data, params = "" }) {
    return axios.put(base + '/free_text_items/' + id + params, data);
};

//           editPlace({ id: 23, data: data });
export const editTodo = function ({ id, data }, params = {}) {
    //                    /api/todos/23 with the data {name:'John', age: 50, ...}
    return axios.put(base + '/todos/' + id + '?_groups[]=order_list_read', data, {params});
};

export const editOrderTodosUser = function({ orderID, userID }) {
    return axios.get(`${base}/orders/todos/${orderID}?userId=${userID}`);
};

export const editTrip = function ({ id, data, params = '' }) {
    return axios.put(base + '/trips/' + id + params, data);
};

export const editTripDetail = function ({ id, data }, params = {}, config = {}) {
    return axios.put(base + '/trip_details/' + id, data, {
        ...config,
        params
    });
};

export const editTripDetailInRequest = function ({ type, id, data }) {
    return axios.put(base + '/' + type + '_requests/' + id, data);
};

export const editInvoice = function ({ id, data }, params = {}) {
    return axios.put(base + '/invoices/' + id, data, {
        params
    });
};
export const editMenu = function ({ id, data }, params = {}) {
    return axios.put(base + '/menus/' + id, data, {
        params
    });
};

export const editInvoiceStatus = function ({ id, data }) {
    return axios.put(base + '/invoices/' + id + '/setStatus', data);
};

export const editUser = function ({ id, data, params = ''}) {
    return axios.put(base + '/users/' + id + params, data);
};

export const editUserCredentials = function ({ id, data }) {
    return axios.put(base + '/admin/users/' + id, data);
};


export const editVoucher = function ({ id, data, config = {} }) {
    return axios.put(base + '/vouchers/' + id, data, config);
};

export const resetVoucher = function({ id, params = {} }) {
    return axios.put(base + '/vouchers/' + id + '/reset', {}, {params})
};

// Preset prices
export const editHotelPresetPrice = function ({id, data, params = '?_groups[]=destination_read&_groups[]=area_read&_groups[]=order_contingent_item_read&_groups[]=order_contingent_item_write'}) {
    //                   /api/hotel_preset_prices/{presetID} with the data {...}
    return axios.put(base + '/hotel_preset_prices/' + id + params, data);
};

export const editFerryPresetPrice = function ({id, data, params = '?_groups[]=port_read&_groups[]=place_read&_groups[]=order_contingent_item_read&_groups[]=order_contingent_item_write'}) {
    //                   /api/ferry_preset_prices/{presetID} with the data {...}
    return axios.put(base + '/ferry_preset_prices/' + id + params, data);
};

export const editOtherPresetPrice = function ({id, data, params = '?_groups[]=destination_read&_groups[]=area_read&_groups[]=other_service_type_read'}) {
    //                   /api/other_preset_prices/{presetID} with the data {...}
    return axios.put(base + '/other_preset_prices/' + id + params, data);
};

export const edit = function (api, data, params = {}, config = {}) {
    return axios.put(base + '/' + api, data, {...config, params});
};

/*
 =======================================================================
 * DELETE methods
 */

export const axiosDelete = function (api, params = {}) {
    return axios.delete(base + '/' + api, {params});
};

export const deleteAgency = function (id) {
    //                    /api/agencies/{id}
    return axios.delete(base + '/agencies/' + id);
};

// Removes a contact and deletes the link to the agency with the id
export const deleteAgencyContact = function ({ agencyID, contactID }) {
    //                    /api/agencies/{agencyID}/contacts/{contactID}
    return axios.delete(base + '/agencies/' + agencyID + '/contacts/' + contactID);
};

export const deleteDay = function (id) {
    return axios.delete(base + '/days/' + id);
};

export const deleteDocument = function (id) {
    return axios.delete(base + '/documents/' + id);
};

export const deleteEmail = function (id) {
    return axios.delete(base + '/emails/' + id);
};

export const deleteCalculationPriceExtra = function (id) {
    return axios.delete(base + '/calculation_price_extras/' + id);
};

export const deleteChecklistItem = function(id) {
    return axios.delete(base + '/checklist_items/' + id);
};

export const deleteFaq = function (id) {
    return axios.delete(base + '/faqs/' + id)
};

export const deleteFreePlace = function (id) {
    return axios.delete(base + '/provider_free_places/' + id);
};

export const deleteHotelPrice = function (id) {
    return axios.delete(base + '/hotel_prices/' + id);
};

export const deletePlaceholder = function ({ apiEndpoint, id }) {
    return axios.delete(base + '/' + apiEndpoint + '/' + id);
};

export const deleteRequest = function ({ apiEndpoint, id }) {
    return axios.delete(base + '/' + apiEndpoint + '/' + id);
};

export const deleteRoom = function (id) {
    return axios.delete(base + '/rooms/' + id)
};

export const deleteRoomList = function (id) {
    return axios.delete(base + '/room_lists/' + id)
};

export const deleteTodo = function (id) {
    return axios.delete(base + '/todos/' + id);
};

export const deleteTripDetail = function (id) {
    return axios.delete(base + '/trip_details/' + id);
};

export const deleteTripDetailTemplate = function (id) {
    return axios.delete(base + '/trip_detail_templates/' + id);
};

export const deleteBulletPointTemplate = function (id) {
    return axios.delete(base + '/bullet_point_templates/' + id);
};

export const deleteVoucherTemplate = function (id) {
    return axios.delete(base + '/voucher_templates/' + id);
};

export const deleteSellingPrice = function (id) {
    return axios.delete(base + '/selling_prices/' + id);
};

export const deleteInfo = function (id) {
    //                      /api/infos/{ID}
    return axios.delete(base + '/infos/' + id);
};

export const deleteOtherPrice = function (id) {
    return axios.delete(base + '/other_service_prices/' + id);
};

export const deleteOtherService = function (id) {
    return axios.delete(base + '/other_services/' + id);
};

export const deleteOrder = function (id) {
    return axios.delete(base + '/orders/' + id);
};

export const deleteOrderContact = function (id) {
    return axios.delete(base + '/order_contacts/' + id);
};

export const deleteImage = function (id) {
    //                      /api/images/{ID}
    return axios.delete(base + '/images/' + id);
};

export const deleteFerryRoute = function (id) {
    return axios.delete(base + '/ferry_routes/' + id)
};

export const deleteTrainRoute = function (id) {
    return axios.delete(base + '/train_routes/' + id)
};

// Preset prices (calculation prices)
export const deleteHotelPresetPrice = function (id) {
    return axios.delete(base + '/hotel_preset_prices/' + id );
};

export const deleteFerryPresetPrice = function (id) {
    return axios.delete(base + '/ferry_preset_prices/' + id );
};

export const deleteOtherPresetPrice = function (id) {
    return axios.delete(base + '/other_preset_prices/' + id );
};


// Replace (DELETE)
export const replaceDayConcept = function (id, params = {}) {
    return axios.delete(`${base}/day_concepts/replace/${id}`, {
        params
    })
};

export const replaceOtherServiceType = function (id, params = {}) {
    return axios.delete(`${base}/other_service_types/${id}/replace`, {
        params
    })
};

export const replaceDestination = function (id, params = {}) {
    return axios.delete(`${base}/destinations/${id}/replace`, {
        params
    })
};

export const replaceArea = function (id, params = {}) {
    return axios.delete(`${base}/areas/${id}/replace`, {
        params
    })
};

export const replacePlace = function (id, params = {}) {
    return axios.delete(`${base}/places/${id}/replace`, {
        params
    })
};

export const replaceProvider = function (id, params = {}) {
    return axios.delete(`${base}/providers/${id}/replace`, {
        params
    })
};

export const replaceAgency = function (id, params = {}) {
    return axios.delete(`${base}/agencies/${id}/replace`, {
        params
    })
};

export const replacePredefinedRouteDetail = function (id, params = {}) {
    return axios.delete(`${base}/predefined_route_details/${id}/replace`, {
        params
    })
};

export const replaceOtherService = function (id, params = {}) {
    return axios.delete(`${base}/other_services/${id}/replace`, {
        params
    })
};

export const changeOrderHotelTypes = function (id, params = {}){
    return axios.put(`${base}/orders/${id}/change_hotel_types`, {}, {
        params
    })
};

/*
 =======================================================================
 * OPTIONS methods
 */
export const axiosOptions = function (api, params = {}, config = {}) {
    return axios.options(base + '/' + api, {...config, params});
};

export const getOptions = function (api, params = {}) {
    return axios.options(base + '/' + api, {params});
};

export const optionsContacts = function () {
    return axios.options(base + '/contacts');
};

export const optionsClientInvoices = function () {
    return axios.options(base + '/client_invoices');
};

export const optionsDocuments = function () {
    return axios.options(base + '/documents');
};

export const optionsDayConcept = function () {
    return axios.options(base + '/day_concepts');
};

export const optionsFaqs = function () {
    return axios.options(base + '/faqs')
};

export const optionsHotelRequests = function () {
    return axios.options(base + '/hotel_requests');
};

export const optionsLocationCharges = function() {
    return axios.options(base + '/location_charges');
};

export const optionsOtherServiceType = function () {
    return axios.options(base + '/other_service_types');
};

export const optionsTodoList = function () {
    return axios.options(base + '/todos');
};

export const optionsOrders = function (params = '') {
    return axios.options(base + '/orders' + params);
};
export const optionsInvoiceOrders = function (params = '') {
    return axios.options(base + '/invoices/order_options' + params);
};
export const optionsOrderContacts = function () {
    return axios.options(base + '/order_contacts');
};

export const optionsOrderConcept = function () {
    return axios.options(base + '/order_concepts');
};

export const optionsTripDetails = function () {
    return axios.options(base + '/trip_details');
};

export const optionsInvoices = function () {
    return axios.options(base + '/invoices');
};

export const optionsImages = function () {
    return axios.options(base + '/images');
};

export const optionsProvider = function (provider) {
    return axios.options(base + '/' + provider);
};

export const optionsRoomlist = function () {
    return axios.options(base + '/order_room_lists');
};

export const optionsUsers = function () {
    return axios.options(base + '/users');
};

export const optionsEmails = function (params = '') {
    return axios.options(base + '/emails' + params);
};

export const optionsVoucherTemplate = function () {
    return axios.options(base + '/voucher_templates');
};

export const getCurrencyExchanges = function (params = {}) {
    return axios.get(base + '/currency_exchanges', {
        params
    });
};

export const getAccountPlanItems = function (params = {}) {
    return axios.get(base + '/account_plan_items', {
        params
    });
};

//Export
export const exportDoc = function (api, method = 'OPTIONS', params = {}) {
    return axios({
        url: base + '/' + api,
        method: method,
        responseType: 'blob',
        params: params
    })
}

// Currencies
const endpoint = (endpoint, options = {}) => {
    const apiEndpoint = base + '/' + endpoint

    return {
        get: (id = null) => {
            if (id) {
                return axios.get(`${apiEndpoint}/${id}`, options.get || {})
            } else {
                return axios.get(apiEndpoint, options.get || {})
            }
        },

        post: (data = {}) => axios.post(apiEndpoint, data,
            {
                ...options.post && {
                    ...(options.post.params && typeof options.post.params === 'function') && {
                        params: options.post.params(data)
                    }
                }
            }),
        put: ({id, ...data}) => axios.put(`${apiEndpoint}/${id}`, data, {
            ...options.put && {
                ...(options.put.params && typeof options.put.params === 'function') && {
                    params: options.put.params(data)
                }
            }
        }),
        delete: (id) => axios.delete(`${apiEndpoint}/${id}`),
        options: () => axios.options(apiEndpoint)
    }
}

export const currency = {
    exchanges: endpoint('currency_exchanges', {
        get: {
            params: {
                _groups: ['currency_exchange:currency_exchange_rate', 'currency_exchange_rate']
            }
        },
        put: {
            params: (data) => {
                return {
                    ...data.rates && {
                        _groups: ['currency_exchange:currency_exchange_rate', 'currency_exchange_rate']
                    }
                }
            }
        }
    }),
    rates: endpoint('currency_exchange_rates'),
    orderRates: endpoint('orders/exchange_rates')
}

//hotelProviderPrices
export const providerPrices = {
    hotelPriceGroups: endpoint('hotel_price_groups', {
        get: {
            params: {
                _groups: [
                    'provider_price_group:price_time_period',
                    'provider_price_group:provider_price',
                    'price_time_period',
                    'provider_price',
                    'room_type',
                    'data_check_read',
                    'hotel_type_read',
                    'area_read',
                ]
            }
        },
        put: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                }
            }
        },
        post: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    }
                }
            }
        },
}),
    ferryPriceGroups: endpoint('ferry_price_groups', {
        get: {
            params: {
                _groups: [
                    'provider_price_group:price_time_period',
                    'provider_price_group:provider_price',
                    'price_time_period',
                    'provider_price',
                    'cabin_type',
                    'ferry_route_read',
                    'data_check_read',
                ]
            }
        },
        put: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    }
                }
            }
        },
        post: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    }
                }
            }
        },
    }),
    trainPriceGroups: endpoint('train_price_groups', {
        get: {
            params: {
                _groups: [
                    'provider_price_group:price_time_period',
                    'provider_price_group:provider_price',
                    'price_time_period',
                    'provider_price',
                    'cabin_type',
                    'provider_train_route',
                    'data_check_read'
                ]
            }
        },
        put: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price']
                    }
                }
            }
        },
        post: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price']
                    }
                }
            }
        },
    }),
    otherPriceGroups: endpoint('other_price_groups', {
        get: {
            params: {
                _groups: [
                    'provider_price_group:price_time_period',
                    'provider_price_group:provider_price',
                    'price_time_period',
                    'provider_price',
                    'data_check_read',
                ]
            }
        },
        put: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    }
                }
            }
        },
        post: {
            params: (data) => {
                return {
                    ...data.timePeriods && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    },
                    ...data.providerPrices && {
                        _groups: ['provider_price_group:price_time_period', 'price_time_period', 'provider_price_group:provider_price', 'provider_price', 'meta_read']
                    }
                }
            }
        },
    })
}
