var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.rows, function (row) {
      return _c(
        "div",
        { staticClass: "bp-panel is-half pt-5" },
        _vm._l(row.cols, function (col) {
          return _c(
            "div",
            {
              staticClass:
                "column is-flex is-justify-content-space-between py-0",
              class: col.columnClass,
              style: col.columnStyle,
            },
            [
              _c("div", { staticClass: "is-flex mb-2" }, [
                _c("span", { staticClass: "my-0 is-align-self-center" }, [
                  _vm._v(_vm._s(col.label)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column is-7 mb-2 py-0" },
                [
                  _c(
                    col.component.type,
                    _vm._b(
                      {
                        key: col.label,
                        tag: "component",
                        attrs: {
                          component: col.component,
                          value: _vm.getItem(col.label),
                        },
                        on: {
                          input: function ($event) {
                            return _vm.saveItem(col, $event)
                          },
                        },
                      },
                      "component",
                      col.component.props,
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }