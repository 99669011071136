import axios from 'axios';
import Vuex from 'vuex';
import debounce from 'lodash/debounce';

import { introTitle } from '@utilities/variables';
import {
    editAirlinePlaceholder,
    getDays,
    getFerries,
    getTrains,
    getHotels,
    getOthers,
    editOrder,
    getBulletPointTemplates, getTrainPlaceholder
} from '@api';

import {
    getIncluded, getHotelPlaceholders, getFerryPlaceholders, getAirlinePlaceholders, getOtherPlaceholders,
    fillOffer, fillConditions, fillFreePlaces, fillBulletPoints, saveOffer, getTrainPlaceholders
} from './functions';

import {defaultAddConditionText, defaultConditions, defaultFreeplaces} from '@clientSpecific/utilities/defaultValues'





const offerStore = {
    state: {
        createMode: 'currentOrder',

        order: {
            id: 0,
            orderNumber: 0,
            mainOffer: null,
            startAt: null,
            endAt: null,
            primaryOrderContact: null,
            organisationFilter: null,
            client: null,
            trip: null,
            orderLocale: 'de_DE'
        },

        offer: {
            id: null,
            type: 'initial',
            attachedOrders: [],
            attachRouteDescription: 'short',
            introTitle: 'formal',
            introText: '',
            offerNr: '',
            contact: null,
            address: null,
            order: null,
            conditions: [],
            bulletPoints: [],
            freeplaces: [],
            minimumPersons: 20
        },
        calculation: {
            contingentPrices: [],
            airlinePrices: [],
            extraPrices: [],
            hotelPrices: [],
            trainPrices: [],
            ferryPrices: [],
            otherPrices: []
        },

        options: {
            introTitle,
            addresses: [],
            accountPlanItems:[],
            contacts: [],
            types: [ // TODO: Fetch from options
                {
                    key: 'initial',
                    label: 'Erstangebot',
                },
                {
                    key: 'updated',
                    label: 'Aktualisiertes Angebot',
                },
                {
                    key: 'confirmation',
                    label: 'Auftragsbestätigung',
                }
            ],
            bulletPoints: [],
            hotels: [],
            ferries: [],
            others: [],
            defaultOrganisation: null
        },

        loading: {
            ferries: false,
            trains: false,
            others: false,
        },

        placeholders: {
            included: [],
            hotel: [],
            ferry: [],
            train: [],
            airline: []
        },

        sortHelper: [],
    },

    mutations: {
        updateStatus: function (state, value) {
            state.isSaving = value;
        },

        updateBulletPointOptions: function (state, bulletPoints) {
            bulletPoints = bulletPoints.map(bullet => {
                return {
                    ...bullet,
                    key: bullet.key ? bullet.key : bullet.id
                }
            })
            state.options.bulletPoints = bulletPoints;
        },

        resetBulletPoints: function (state, bulletPoints) {
            if(!state.order.organisationFilter){
                state.order.organisationFilter = state.options.defaultOrganisation;
            }

            state.offer = Object.assign({}, state.offer, {
                bulletPoints: bulletPoints.filter(bullet => {
                    if(bullet.locale !== state.order.orderLocale) {
                        return false;
                    }
                    if(!state.order.organisationFilter){
                        return true;
                    }
                    if(!bullet.organisations || bullet.organisations.length === 0){
                        return false;
                    }
                    return bullet.organisations.some(organisation => state.order.organisationFilter.id === organisation.id);
                }),
            })
        },

        updateOrder: function (state, order) {
            state.order = Object.assign({}, state.order, order);
        },

        updateOffer: function (state, offer) {
            state.offer = Object.assign({}, state.offer, offer);
        },

        updateCalculation: function (state, calculation) {
            state.calculation = Object.assign({}, state.calculation, calculation);

            const doubleRooms = calculation.contingentPrices
                .filter(price => price.orderHotelRoom)
                .filter(price => {
                    const { type, persons } = price.orderHotelRoom.type;
                    return type === 'standard' && persons === 2;
                }).map(({numberOfPersons}) => numberOfPersons)

            if (doubleRooms.length > 0) {
                state.offer.minimumPersons = Math.min(...doubleRooms)
            }
        },

        updateLoading: function (state, loading) {
            state.loading = Object.assign({}, state.loading, loading);
        },

        updateCreateMode: function (state, value) {
            state.createMode = value;
        },

        updateOptions: function (state, newOptions) {
            state.options = Object.assign({}, state.options, newOptions);
        },

        addAttachedOrder: function (state, order) {
            state.offer.attachedOrders = [...state.offer.attachedOrders, order];
        },

        removeAttachedOrder: function (state, order) {
            state.offer.attachedOrders = state.offer.attachedOrders.filter(attachedOrder => attachedOrder.id !== order.id);
        },

        updatePlaceholders: function (state, placeholders) {
            state.placeholders = Object.assign({}, state.placeholders, placeholders);
        },

        /* initializeConditions: function (state, conditions) {
            state.offer = Object.assign({}, state.offer, conditions);
        }, */

        addCondition: function (state, subcategory) {
            state.offer.conditions = [...state.offer.conditions, JSON.parse(JSON.stringify({
                category: '',
                subcategory: '',
                deadlineDate: null,
                deadlineText: '',
                conditionText: defaultAddConditionText(state, subcategory),
                key: + new Date,
                type: 'days',
                days: null,
                afterDays: null,
                ...subcategory
            }))];
        },

        renameSubcategory: function (state, newItem) {
            const oldItem = state.offer.conditions.find(item => item.key === newItem.key);

            state.offer.conditions = state.offer.conditions.map(item => {
                if (item.subcategory === oldItem.subcategory) {
                    return Object.assign({}, item, {
                        subcategory: newItem.subcategory
                    });
                }

                return item;
            });
        },

        updateCondition: function (state, item) {
            const foundIndex = state.offer.conditions.findIndex(element => element.key === item.key);
            state.offer.conditions.splice(foundIndex, 1, item);
        },

        updateConditions: function (state, conditions) {
            state.offer.conditions = [...conditions];
        },

        removeCondition: function (state, item) {
            state.offer.conditions = state.offer.conditions.filter(condition => condition.subcategory !== item.subcategory);
        },

        removeSubcategory: function (state, item) {
            state.offer.conditions = state.offer.conditions.filter(condition => condition.key !== item.key)
        },

        addCustomCategory: function (state) {
            if (state.offer.conditions.filter(condition => condition.category === '').length === 0) {
                state.offer.conditions = [...state.offer.conditions, {
                    category: '',
                    subcategory: '',
                    deadlineDate: null,
                    deadlineText: '',
                    conditionText: '',
                    key: + new Date,
                    type: 'days',
                    days: null,
                    afterDays: null
                }]
            }
        },

        renameCustomCategory: function (state, {newName, oldName}) {
            if (newName !== oldName) {
                if (newName === '') {
                    state.offer.conditions = state.offer.conditions
                        .filter(condition => condition.name !== '')
                } else {
                    state.offer.conditions = state.offer.conditions
                        .map(condition => {
                            if (condition.category === oldName) {
                                return Object.assign({}, condition, {
                                    category: newName
                                })
                            }

                            return condition
                        })
                }
            }
        },

        deleteCustomCategory: function (state, category) {
            state.offer.conditions = state.offer.conditions
                .filter(condition => condition.category !== category)
        },

        addBulletPoint: function (state, item) {
            state.offer.bulletPoints = [...state.offer.bulletPoints, Object.assign({
                key: item.key ? item.key : + new Date,
                content: '',
                section: null,
            }, item)];
        },

        updateBulletPoint: function (state, item) {
            const foundIndex = state.offer.bulletPoints.findIndex(element => element.key === item.key);
            state.offer.bulletPoints.splice(foundIndex, 1, item);
        },

        removeBulletPoint: function (state, item) {
            state.offer.bulletPoints = state.offer.bulletPoints.filter(bulletPoint => bulletPoint.key !== item.key);
        },

        addFreePlace: function (state) {
            state.offer.freeplaces = [...state.offer.freeplaces, {
                key: + new Date,
                freePlaces: 1,
                numberOfPersons: null,
                type: 'double'
            }];
        },

        updateFreePlaces: function (state, items) {
            state.offer.freeplaces = items;
        },


        updateFreePlace: function (state, item) {
            const newItem = JSON.parse(JSON.stringify(item));
            const foundIndex = state.offer.freeplaces
                .findIndex(element => element.key === item.key);

            state.offer.freeplaces.splice(foundIndex, 1, newItem);
        },

        removeFreePlace: function (state, item) {
            state.offer.freeplaces = state.offer.freeplaces.filter(freeplace => freeplace.key !== item.key);
        },

        updateSortHelper: function (state, sortHelper) {
            state.sortHelper = [...sortHelper];
        }
    },


    actions: {
        resetConditions: function ({state, commit}) {
            commit('resetBulletPoints', state.options.bulletPoints)
            state.offer = Object.assign({}, state.offer, {
                conditions: defaultConditions(state),
                freeplaces: defaultFreeplaces(),
                minimumPersons: 20
            })
        },

        updateOffer: function ({ commit, dispatch }, offer) {
            commit('updateOffer', offer);
        },

        editOrder: function ({commit, dispatch}, {data, apiData = {}, params = ''}) {

            commit('updateOrder', data);
            apiData = apiData ? apiData : data;
            editOrder({
                id: this.state.order.id,
                data: apiData,
                params: params
            })
        },

        addAttachedOrder: function ({ commit, dispatch }, order) {
            commit('addAttachedOrder', order);
            dispatch('saveOffer', order)
        },

        removeAttachedOrder: function ({ commit, dispatch }, order) {
            commit('removeAttachedOrder', order);

            editOrder({
                id: order.id,
                data: { attachedOffer: null },
                params: '?_groups[]=order_offer_write'
            });
        },

        updateCondition: function ({ commit, dispatch }, newItem) {
            commit('updateCondition', newItem);
        },

        renameSubcategory: function ({ commit, dispatch }, newItem) {
            commit('renameSubcategory', newItem);
        },

        removeCondition: function ({ commit, dispatch }, item) {
            commit('removeCondition', item);
        },

        updateFreePlace: function ({ commit, dispatch }, item) {
            commit('updateFreePlace', item);
        },

        removeFreePlace: function ({ commit, dispatch }, item) {
            commit('removeFreePlace', item);
        },

        updateBulletPoint: function ({ commit, dispatch }, item) {
            commit('updateBulletPoint', item);
        },

        removeBulletPoint: function ({ commit, dispatch }, item) {
            commit('removeBulletPoint', item);
        },

        setOrder: function ({commit, state, dispatch}, order) {

            getDays(`?_groups[]=offer_read&_groups[]=provider_read_list&order.id=${state.order.id}`)
                .then(response => {
                    const {
                        client,
                        trip,
                        orderNumber,
                        calculation,
                        startAt,
                        mainOffer,
                        attachedOffer,
                        organisationFilter,
                        orderLocale,
                        primaryOrderContact,
                        endAt
                    } = order
                    const days = response.data;

                    commit('addAttachedOrder', order);

                    commit('updateOptions', {
                        contacts:  client.contacts,
                        accountPlanItems: client.accountPlanItems,
                        addresses:  client.addresses,
                    });
                    commit('updatePlaceholders', {
                        included: getIncluded(days),
                        hotel: getHotelPlaceholders(days),
                        ferry: getFerryPlaceholders(days),
                        train: getTrainPlaceholders(days),
                        other: getOtherPlaceholders(days),
                        airline: getAirlinePlaceholders(days)
                    });

                    commit('updateOrder', {
                        startAt,
                        endAt,
                        mainOffer: !!mainOffer ? mainOffer : null,
                        organisationFilter: organisationFilter,
                        orderLocale: orderLocale,
                        orderNumber: orderNumber,
                        primaryOrderContact: primaryOrderContact,
                        client: client,
                        trip: trip
                    });

                    if (calculation !== null) {
                        dispatch('fetchCalculation', calculation);
                    }

                    if (!mainOffer && !attachedOffer) {
                        dispatch('resetConditions');
                        dispatch('fetchBulletPoints', true)
                    } else {
                        dispatch('fetchBulletPoints', false)
                    }

                    if (!mainOffer && attachedOffer && attachedOffer.order) {
                        commit('updateCreateMode', 'otherOrder');
                    }

                    if (attachedOffer && attachedOffer.order) {
                        commit('updateOffer', fillOffer(attachedOffer, order));
                    }

                    commit('updateSortHelper', [].concat(...days.map(day => day.sortHelper)));
                })
        },

        fetchCalculation: function ({ commit }, calculationAPI) {
            axios.get(calculationAPI + '/prices')
                .then(response => {
                    commit('updateCalculation', response.data);
                });
        },

        fetchBulletPoints: function ({ commit }, reset = false) {

            getBulletPointTemplates()
                .then(response => {
                    response.data.map(item => {
                        item.key = item.id;
                        delete item.id;
                        return item;
                    })
                    commit('updateBulletPointOptions', response.data)
                    if(reset){
                        commit('resetBulletPoints', response.data);
                    }
                });
        },

        saveAirlinePlaceholders: function ({state}) {
            return Promise.all(state.placeholders.airline.map(placeholder => {
                return editAirlinePlaceholder({
                    id: placeholder.id,
                    data: {
                        ...placeholder.offerProvider && {offerProvider : `/api/providers/${placeholder.offerProvider.id}`},
                        offerPriceType: placeholder.offerPriceType
                    }
                }, {
                    _groups: ['order_offer_write', 'order_placeholder_write', 'order_offer_read', 'order_placeholder_read', 'provider_read', 'provider_write']
                })
            }))
        },

        saveOrganisation: function ({commit, state}, organisation) {

            commit('updateStatus', true);
            commit('updateOrder', {
                organisationFilter: organisation
            });
            editOrder({
                id: state.order.id,
                data: {
                    organisationFilter: organisation
                },
            }).then(response => {
                commit('updateStatus', false);
            }, error => {
                commit('updateStatus', false);
            })
        },


        saveLanguage: function ({commit, state}, language) {
            commit('updateStatus', true);
            editOrder({
                id: state.order.id,
                data: {
                    orderLocale: language
                },
            }).then(response => {
                commit('updateOrder', {
                    orderLocale: language
                });
                commit('updateStatus', false);
            }, error => {
                commit('updateStatus', false);
            })
        },

        saveOffer: function ({commit, state, dispatch}, order) {
            commit('updateStatus', true);
            dispatch('saveAirlinePlaceholders')

            return new Promise((resolve, reject) => {
                if (state.createMode === 'currentOrder') {
                    saveOffer(state, order)
                        .then(response => {
                            const { id } = response.data;
                            commit('updateOffer', {
                                id,
                                conditions: fillConditions(response.data, order),
                                freePlaces: fillFreePlaces(response.data),
                                bulletPoints: fillBulletPoints(response.data)
                            });
                            commit('updateStatus', false);
                            resolve();
                        }, error => {
                            reject(error);
                            commit('updateStatus', false);
                        })
                } else {
                    editOrder({
                        id: state.order.id,
                        data: {
                            mainOffer: null
                        },
                        params: '?_groups[]=order_offer_write'
                    }).then(response => {
                        resolve();
                        commit('updateStatus', false);
                    }, error => {
                        reject(error);
                        commit('updateStatus', false);
                    })
                }
            })
        },


        searchFerry: debounce(({ commit }, params = '') => {
            if (params !== '') {
                commit('updateLoading', { ferries: true });

                getFerries(params).then(response => {
                    commit('updateOptions', { ferries: response.data });
                    commit('updateLoading', { ferries: false });
                });
            }
        }, 400),

        searchTrain: debounce(({ commit }, params = '') => {
            if (params !== '') {
                commit('updateLoading', { trains: true });

                getTrains(params).then(response => {
                    commit('updateOptions', { trains: response.data });
                    commit('updateLoading', { trains: false });
                });
            }
        }, 400),


        searchOther: debounce(({ commit }, params = '') => {
            if (params !== '') {
                commit('updateLoading', { others: true });

                getOthers(params).then(response => {
                    commit('updateOptions', { others: response.data });
                    commit('updateLoading', { others: false });
                });
            }
        }, 400),
    }
};

export default new Vuex.Store(offerStore);
export {offerStore};
