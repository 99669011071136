<template>
    <div>
        <template v-for="locale in value">
            <gb-flag
                :code="getFlag(locale)"
                size="small"
                iconPath="/assets/img/flags/"
            />
        </template>
    </div>
</template>

<script>
import Cell from './cell';
import {getFlag} from "@utilities/functions";

export default {
    mixins: [ Cell ],

    props: {
        value: {
            required: true
        },
    },

    methods: {
        getFlag
    }
}
</script>
